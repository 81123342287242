<template>
  <div class="box-card flex-box vertical" :class="{ 'show-table': showTable, 'small-box': small }">
    <div class="card-header" v-if="!hideTitle">
      <div class="card-title-box">
        <router-link :to="titleRouter" class="card-title ellipsis" :title="title" v-if="titleRouter">{{ title }}</router-link>
        <div class="card-title ellipsis" :title="title" v-else>{{ title }}</div>
        <div class="card-sub-title" v-if="subTitle">{{ subTitle }}</div>
      </div>
      <slot name="query" v-if="$slots.query" />
      <a-radio-group v-model="value" size="small" v-else-if="showQuery" class="query-radio-group">
        <a-radio-button :value="option.value" v-for="option in options" :key="option.value">
          {{ option.label }}
        </a-radio-button>
      </a-radio-group>
      <slot name="header" />
    </div>
    <slot name="panel" />
    <div class="card-body flex-grow scroll-area">
      <slot v-if="$slots.default" />
      <a-table
        ref="dataTable"
        :columns="columns"
        :data-source="data"
        :row-key="(record, index) => record.id || record.key || index + 1"
        v-else-if="showTable"
        :scroll="{ y: h }"
        :pagination="pagination"
        v-bind="{ ...tableExtraProps }"
        @change="handleTableChange"
        class="card-table"
        :class="{ 'show-table-header-top-line': showTableHeaderTopLine }"
      ></a-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "BoxCard",
  props: {
    hideTitle: { type: Boolean, default: false },
    title: { type: [String, Number], default: "标题" },
    titleRouter: { type: [String, Object] },
    subTitle: { type: [String, Number] },
    showQuery: { type: Boolean, default: false }, // 展示查询条件
    active: { type: [Number, String] }, // 查询条件的值
    options: { type: Array, default: () => [] }, // 查询条件的列表
    showTable: { type: Boolean, default: false }, // 展示表格
    columns: { type: Array, default: () => [] }, // 表格列的配置
    data: { type: Array, default: () => [] }, // 表格数据
    pagination: { type: [Boolean, Object], default: false },
    tableExtraProps: { type: Object, default: () => ({}) }, // 表格的额外配置
    small: { type: Boolean, default: false }, // 小容器
    showTableHeaderTopLine: { type: Boolean, default: false }, // 展示表格的表头顶部的分割线
  },
  data() {
    return {
      h: 40,
    };
  },
  computed: {
    value: {
      get: function() {
        return this.active;
      },
      set: function(val) {
        this.$emit("update:active", val);
      },
    },
  },
  mounted() {
    this.setHeight();
    window.addEventListener("resize", this.setHeight, false);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setHeight, false);
  },
  methods: {
    setHeight() {
      let dataTale = this.$refs.dataTable;
      if (dataTale) {
        let area = this.$el.querySelector(".card-body");
        let thead = area.querySelector("thead");
        this.h = area.clientHeight - thead.clientHeight;
        if (this.pagination) {
          this.h -= 64;
        }
      }
    },
    handleTableChange(pagination) {
      this.$emit("tableChange", pagination);
    },
  },
};
</script>

<style lang="less" scoped>
@import url(../../../../common/less/radio.less);

::v-deep .card-table {
  .ant-table-header {
    background-color: transparent;
    th {
      background-color: transparent;
    }
  }
  .ant-table-pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .ant-pagination-total-text {
      margin-right: auto;
    }
  }
  td {
    padding: 14px 16px !important;
  }
}

.box-card {
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  padding: 30px 32px 16px;
  box-sizing: border-box;
  &.show-table {
    padding-bottom: 30px;
  }
}

.card-header {
  flex-shrink: 0;
  padding-bottom: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card-title-box {
  min-width: 0;
  flex: 1;
  display: flex;
  align-items: center;
  cursor: default;
  white-space: nowrap;
  .card-title {
    color: rgba(0, 0, 0, 0.9);
    font-family: "PingFang SC";
    font-size: 20px;
    line-height: 28px;
  }
  .card-sub-title {
    color: rgba(0, 0, 0, 0.9);
    margin-left: 12px;
    font-family: "PingFang SC";
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
  }
}
.query-radio-group {
  flex-shrink: 0;
  white-space: nowrap;
}

.card-table {
  height: 100%;
  ::v-deep &.show-table-header-top-line {
    th {
      border-top: 1px solid #e8e8e8;
    }
  }
}

.small-box.box-card {
  padding: 0;
  .card-header {
    padding-bottom: 4px;
  }
  ::v-deep .card-table {
    th {
      padding: 8px;
    }
    .ant-table-placeholder {
      padding: 8px;
    }
    .ant-empty-normal {
      margin: 4px;
    }
    .ant-empty-image {
      display: none;
    }
    .ant-empty-description {
      height: 40px;
      line-height: 40px;
    }
  }
}
</style>
