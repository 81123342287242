<template>
  <a-row :gutter="gutter" type="flex">
    <a-col :span="6"></a-col>
    <a-col :span="6">
      <BoxCard title="总人数" :subTitle="countInfo.total" class="height mini">
        <div class="department-info-list">
          <div class="department-info-item">
            <div class="info-item-title">僧众总人数</div>
            <div class="info-item-value">{{ countInfo.buddhistMonks }}</div>
          </div>
          <div class="department-info-item">
            <div class="info-item-title">职工总人数</div>
            <div class="info-item-value">{{ countInfo.workers }}</div>
          </div>
        </div>
      </BoxCard>
    </a-col>
    <a-col :span="6">
      <BoxCard title="今日汇报" class="height mini">
        <div class="department-info-list">
          <div class="department-info-item">
            <div class="info-item-title">已汇报</div>
            <div class="info-item-value">{{ reportInfo.reported }}</div>
          </div>
          <div class="department-info-item">
            <div class="info-item-title">未汇报</div>
            <div class="info-item-value">{{ reportInfo.unreported }}</div>
          </div>
        </div>
      </BoxCard>
    </a-col>
    <a-col :span="6">
      <BoxCard hide-title class="height mini notice-box">
        <NoticeList :list="noticeList" />
      </BoxCard>
    </a-col>
    <a-col :span="6">
      <BoxCard
          title="考勤统计"
          class="height normal"
          show-query
          :options="attendanceOptions"
          :active.sync="attendanceActive"
      >
        <chart :option="attendanceOption" class="chart-box" />
      </BoxCard>
    </a-col>
    <a-col :span="6">
      <BoxCard
          title="响应效率"
          show-table
          :columns="responseColumns"
          :data="responseData"
          class="height normal"
          show-query
          :options="conditionOptions"
          :active.sync="responseActive"
      ></BoxCard>
    </a-col>
    <a-col :span="6">
      <BoxCard
          title="部门设备"
          show-table
          :columns="deviceColumns"
          :data="deviceData"
          class="height normal"
      ></BoxCard>
    </a-col>
    <a-col :span="6">
      <BoxCard title="固定资产" class="height normal">
        <chart :option="assetsOption" class="chart-box" />
      </BoxCard>
    </a-col>
    <a-col :span="12">
      <Project :value="value" :deptList="deptList" class="height normal" />
    </a-col>
    <a-col :span="6">
      <BoxCard
          title="今日申购"
          show-table
          show-table-header-top-line
          :columns="subscribeColumns"
          :data="subscribeData"
          class="height normal"
      >
        <template #panel>
          <div class="panel-total">{{ subscribeCount }}</div>
        </template>
      </BoxCard>
    </a-col>
    <a-col :span="6">
      <BoxCard hide-title class="height normal consume-box">
        <div class="full-content flex-box vertical">
          <BoxCard
              title="今日领用"
              show-table
              show-table-header-top-line
              :columns="todayUseColumns"
              :data="todayUseData"
              small
              class="flex-grow small-card"
          >
            <template #panel>
              <div class="panel-total no-margin">{{ todayUseCount }}</div>
            </template>
          </BoxCard>
          <BoxCard
              title="桶装水领用"
              show-table
              show-table-header-top-line
              :columns="waterColumns"
              :data="waterData"
              small
              class="flex-grow small-card"
          >
            <template #query>
              <a-icon type="right" class="arrow-icon" @click="goWater" />
            </template>
            <template #panel>
              <div class="panel-info">
                <div class="panel-info-item">
                  <span class="panel-info-text">今日</span>
                  <span class="panel-info-count">{{ use.today }}</span>
                </div>
                <div class="panel-info-item">
                  <span class="panel-info-text">本月</span>
                  <span class="panel-info-count">{{ use.month }}</span>
                </div>
              </div>
            </template>
          </BoxCard>
        </div>
      </BoxCard>
    </a-col>
  </a-row>
</template>

<script>
import { getTemple } from "@/common/js/storage";
import { capitalizeFirstLetter } from "@/common/js/tool";
import BoxCard from "./components/box-card.vue";
import { attendanceOptions, getAttendancePieChartOption, timeOptions } from "./constant";
import Project from "./layouts/Project.vue";
import NoticeList from "./layouts/NoticeList.vue";

const KEYS = ["attendance", "assets"];

export default {
  name: "Warehouse",
  components: { BoxCard, Project, NoticeList },
  props: {
    deptList: { type: Array },
    value: { type: Number },
  },
  data() {
    const options = {};
    KEYS.forEach((key) => (options[`${key}Option`] = {}));
    return {
      deptName: "库房——物",
      gutter: [16, 16],
      ...options,
      info: null,
      countInfo: {
        total: 0, // 全寺总人数
        buddhistMonks: 0, // 僧众总人数
        workers: 0, // 职工总人数
      },
      // 今日汇报
      reportInfo: {
        reported: 0, // 已汇报
        unreported: 0, // 未汇报
      },
      noticeList: [],
      /* 考勤统计 */
      attendanceActive: 0,
      attendanceOptions,
      conditionOptions: timeOptions.slice(1),
      /* 响应效率 */
      responseActive: 2,
      responseColumns: [
        {
          title: "排名",
          dataIndex: "rank",
          customRender: (text, record, index) => <div class={`rank ${index < 3 ? "top" : ""}`}>{++index}</div>,
          align: "center",
        },
        { title: "姓名", dataIndex: "name", align: "center" },
        { title: "平均时长(h)", dataIndex: "average_duration", align: "center", width: 120 },
      ],
      responseData: [],
      /* 部门设备 */
      deviceColumns: [
        { title: "设备名称", dataIndex: "device_name", align: "center", customRender: (text) => text?.name },
        { title: "保管人", dataIndex: "name", align: "center" },
      ],
      deviceData: [],
      /* 今日申购 */
      subscribeColumns: [
        {
          title: "商品名称",
          dataIndex: "subscribe_goods",
          align: "center",
        },
        {
          title: "申购数量",
          customRender: (text) => `${text?.num || 0}${text?.subscribe_goods_unit || ""}`,
          align: "center",
        },
        {
          title: "堂口名称",
          dataIndex: "ketang",
          customRender: (text) => text?.name || "",
          align: "center",
        },
      ],
      subscribeData: [],
      subscribeCount: 0,
      /* 今日领用 */
      todayUseColumns: [
        {
          title: "商品名称",
          dataIndex: "goods_name",
          align: "center",
        },
        {
          title: "领用数量",
          dataIndex: "num",
          customRender: (text) => `${text?.num || 0}${text.goods_unit || ""}`,
          align: "center",
        },
        {
          title: "堂口名称",
          dataIndex: "ketang",
          customRender: (text) => text?.name || "",
          align: "center",
        },
      ],
      todayUseData: [],
      todayUseCount: 0,
      /* 桶装水领用 */
      use: {
        today: 0,
        month: 0,
      },
      waterColumns: [
        {
          title: "商品名称",
          dataIndex: "goods_name",
          align: "center",
        },
        {
          title: "领用数量",
          dataIndex: "num",
          customRender: (text) => `${text?.num || 0}${text.goods_unit || ""}`,
          align: "center",
        },
        {
          title: "堂口名称",
          dataIndex: "ketang",
          customRender: (text) => text?.name || "",
          align: "center",
        },
      ],
      waterData: [],
    };
  },
  computed: {
    dept: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {
    attendanceActive() {
      this.getAttendanceChart();
    },
    responseActive() {
      this.setResponseData();
    },
  },
  created() {
    this.getData();
    this.getNotice();
  },
  methods: {
    roundNum(num) {
      if (num) {
        return Number(num.split(".")[0]);
      } else {
        return 0;
      }
    },
    // 动态通知
    getNotice() {
      let url = `/admin/apply?page=1&pageSize=10&sort=-id&is_oa=2&temp_type=1,3,5&temple_id=${getTemple()}&filter[status]=4`;
      if (this.value) {
        url += `&dept=${this.value}`;
      }
      this.$axios({ url, noTempleFilter: true }).then((res) => {
        this.noticeList = res.data || [];
      });
    },
    async getData() {
      this.$emit("loading");
      let params = {};
      let temple = getTemple();
      if (temple) {
        params.temple_id = temple;
      }
      try {
        const res1 = await this.$axios({
          url: "/admin/dept-datas/get-data",
          method: "GET",
          params,
          noTempleFilter: true,
        });
        const res2 = await this.$axios({
          url: "/admin/dept-datas/get-ku-fang",
          method: "GET",
          params,
          noTempleFilter: true,
        });
        this.info = { ...res1, ...res2 };
        this.setCountInfo();
        this.setReportInfo();
        this.setResponseData();
        this.setDeviceData();
        this.setSubscribeData();
        this.setTodayUseData();
        this.setWaterData();
        this.init();
      } catch (err) {
        console.log("getData-err:", err);
      }
      this.$emit("loaded")
    },
    // 总人数
    setCountInfo() {
      const { total_num, sz_num, zg_num } = this.info?.total_num || {};
      this.countInfo.total = total_num || 0;
      this.countInfo.buddhistMonks = sz_num || 0;
      this.countInfo.workers = zg_num || 0;
    },
    // 今日汇报
    setReportInfo() {
      const { reported, unreported } = this.info?.today_report || {};
      this.reportInfo.reported = reported || 0;
      this.reportInfo.unreported = unreported || 0;
    },
    // 响应效率
    setResponseData() {
      const { month, year } = this.info?.efficiency || {};
      switch (this.responseActive) {
        case 2: // 本月
          this.responseData = month || [];
          break;
        case 3: // 本年
          this.responseData = year || [];
          break;
        default:
          this.responseData = [];
      }
    },
    // 部门设备
    setDeviceData() {
      this.deviceData = this.info?.dept_device || [];
    },
    // 今日申购
    setSubscribeData() {
      const { subscribe } = this.info || {};
      this.subscribeData = subscribe.list || [];
      this.subscribeCount = subscribe.today_total_num || 0;
    },
    // 今日领用
    setTodayUseData() {
      const { warehouse } = this.info || {};
      this.todayUseData = warehouse.list || [];
      this.todayUseCount = warehouse.count || 0;
    },
    // 桶装水领用
    setWaterData() {
      const { water } = this.info || {};
      this.use.today = water.today_total_num || 0;
      this.use.month = water.month_total_num || 0;
      this.waterData = water.list || [];
    },
    init() {
      this.getAllChart(KEYS);
    },
    // 获取所有图表
    getAllChart(keys) {
      keys?.forEach((key) => {
        key = capitalizeFirstLetter(key);
        this[`get${key}Chart`]();
      });
    },
    // 考勤
    getAttendanceChart() {
      const kq = this.info?.kq || [];
      const count = kq[this.attendanceActive]?.count || []; // count: ["应到人数"， "在职", "请假", "外派"]
      const data = [
        { name: "在职", value: 0 },
        { name: "请假", value: 0 },
        { name: "外派", value: 0 },
      ];
      const total = count[0] || 0;
      data.forEach((item, index) => {
        item.value = count[index + 1] || 0;
      });
      this.attendanceOption = getAttendancePieChartOption(data, total);
    },
    // 固定资产
    getAssetsChart() {
      const { in_warehouse, out_warehouse, scrap, total_num } = this.info?.fixed || {};
      const data = [
        { name: "库内", value: +in_warehouse || 0 },
        { name: "库外", value: +out_warehouse || 0 },
        { name: "报废", value: +scrap || 0 },
      ];
      const total = total_num || 0;
      this.assetsOption = getAttendancePieChartOption(data, total, "总数量");
    },
    goWater() {
      this.$router.push({ name: "BottledWater" });
    },
  },
};
</script>

<style lang="less" scoped>
@import url(../../../common/less/department.less);

.department-info-list {
  padding-top: 2px;
}
.assets-dashboard {
  position: relative;
  width: 154px;
  height: 78px;
  border: 16px solid #ff8c00;
  border-radius: 50% 50% 0 0/100% 100% 0 0;
  border-bottom: none;
  box-sizing: border-box;
  color: #171725;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  margin: 0 44px;
  &::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 122px;
    height: 62px;
    border: 8px solid #efefff;
    border-radius: 50% 50% 0 0/100% 100% 0 0;
    border-bottom: none;
    box-sizing: border-box;
  }
}
.assets-overview-title {
  margin-top: 16px;
  text-align: center;
}
.percent-text {
  color: var(--title_text);
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

.notice-box {
  padding: 20px;
}
</style>
