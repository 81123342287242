import * as echarts from "echarts";

export const timeOptions = [
  { label: "今日", value: 1 },
  { label: "本月", value: 2 },
  { label: "本年", value: 3 },
];

export const timeWeekOptions = [
  { label: "今日", value: 1 },
  { label: "本月", value: 2 },
  { label: "本年", value: 3 },
];

export const attendanceOptions = [
  { label: "僧众", value: 0 },
  { label: "职工", value: 1 },
];

export const statusOptions = [
  { label: "未完成", value: 0, color: "#FF4800" },
  { label: "已完成", value: 1, color: "#00C800" },
];

// 性别比例饼图
export const getSexPieChartOption = function(data) {
  const color = ["#3AACFF", "#FF695F"];
  const option = {
    tooltip: {
      trigger: "item",
    },
    legend: {
      orient: "vertical",
      align: "left",
      left: "right",
      top: "bottom",
      itemWidth: 12,
      itemHeight: 4,
      formatter: function(name) {
        let target = data.find((item) => item.name == name);
        return name + " " + target.value + "%";
      },
    },
    color,
    series: [
      {
        name: "性别比例",
        type: "pie",
        radius: "100%",
        center: ["20%", "50%"],
        data,
        label: {
          show: false,
        },
        silent: true,
        legendHoverLink: false,
        emphasis: {
          disabled: true, // 5.3.0以上支持
        },
      },
    ],
  };
  return option;
};
// 学历分布饼图
export const getQualificationPieChartOption = function(data) {
  const color = ["#16D557", "#FF8C00", "#00A3FF", "#FFC700", "#BD00FF"];
  const option = {
    tooltip: {
      trigger: "item",
    },
    grid: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      containLabel: true,
    },
    legend: {
      orient: "vertical",
      align: "left",
      left: "right",
      top: "bottom",
      itemWidth: 12,
      itemHeight: 4,
      padding: 2,
      itemGap: 2,
      textStyle: {
        fontSize: 12,
        rich: {
          name: {
            width: 60,
          },
        },
      },
      formatter: function(name) {
        let target = data.find((item) => item.name == name);
        return `{name|${name}} ${target.value}%`;
      },
    },
    color,
    series: [
      {
        name: "学历分布",
        type: "pie",
        radius: "100%",
        center: ["20%", "50%"],
        data,
        label: {
          show: false,
        },
        silent: true,
        legendHoverLink: false,
        emphasis: {
          disabled: true, // 5.3.0以上支持
        },
      },
    ],
  };
  return option;
};
// 年龄分布饼图
export const getAgePieChartOption = function(data) {
  const color = ["#16D557", "#FF8C00", "#00A3FF", "#BD00FF"];
  const option = {
    tooltip: {
      trigger: "item",
    },
    grid: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      containLabel: true,
    },
    legend: {
      orient: "vertical",
      align: "left",
      left: "right",
      top: "bottom",
      itemWidth: 12,
      itemHeight: 4,
      padding: 2,
      itemGap: 2,
      textStyle: {
        fontSize: 12,
        rich: {
          name: {
            width: 50,
          },
        },
      },
      formatter: function(name) {
        let target = data.find((item) => item.name == name);
        return `{name|${name}} ${target.value}%`;
      },
    },
    color,
    series: [
      {
        name: "年龄分布",
        type: "pie",
        radius: "100%",
        center: ["20%", "50%"],
        data,
        label: {
          show: false,
        },
        silent: true,
        legendHoverLink: false,
        emphasis: {
          disabled: true, // 5.3.0以上支持
        },
      },
    ],
  };
  return option;
};
// 志工状态分布饼图
export const getStatusPieChartOption = function(data) {
  const color = ["#FF8C00", "#16D557", "#FF4800", "#978A83"];
  const option = {
    tooltip: {
      trigger: "item",
    },
    grid: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      containLabel: true,
    },
    legend: {
      orient: "vertical",
      align: "left",
      left: "right",
      top: "bottom",
      itemWidth: 12,
      itemHeight: 4,
      padding: 2,
      itemGap: 2,
      textStyle: {
        fontSize: 12,
        rich: {
          name: {
            width: 60,
          },
        },
      },
      formatter: function(name) {
        let target = data.find((item) => item.name == name);
        return `{name|${name}} ${target.value}%`;
      },
    },
    color,
    series: [
      {
        name: "志工状态分布",
        type: "pie",
        radius: "100%",
        center: ["20%", "50%"],
        data,
        label: {
          show: false,
        },
        silent: true,
        legendHoverLink: false,
        emphasis: {
          disabled: true, // 5.3.0以上支持
        },
      },
    ],
  };
  return option;
};
// 水平柱状图
export const getHorizontalBarChartOption = function(data, name = "人数") {
  const option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        // 坐标轴指示器，坐标轴触发有效
        type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
      },
    },
    grid: {
      left: 0,
      right: 20,
      top: 0,
      bottom: 0,
      containLabel: true,
    },
    xAxis: [
      {
        type: "value",
        minInterval: 1,
        splitLine: {
          lineStyle: {
            type: "dashed",
          },
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
      },
    ],
    yAxis: [
      {
        type: "category",
        inverse: true,
        data: data.map((item) => item.name),
        axisLabel: {
          color: "rgba(0,0,0,0.4)",
        },
        axisTick: {
          alignWithLabel: true,
          lineStyle: {
            color: "#E7E7E7",
          },
        },
        axisLine: {
          lineStyle: {
            color: "#E7E7E7",
          },
        },
      },
    ],
    series: [
      {
        name,
        type: "bar",
        barWidth: 20,
        data,
        label: {
          show: true,
          position: "insideRight",
          formatter: "{c}",
          textStyle: {
            color: "#fff",
          },
        },
      },
    ],
  };
  return option;
};
// 今日志工报名/到岗人数
export const getEnrollBarChartOption = function(data) {
  const color = ["#FF8C00", "#FFCE7E"];
  const option = {
    grid: {
      left: 0,
      right: 0,
      top: 20,
      bottom: 30,
      containLabel: true,
    },
    legend: {
      bottom: 0,
      itemGap: 24,
      itemWidth: 12,
      itemHeight: 4,
    },
    color,
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    dataset: {
      dimensions: ["product", "报名", "到岗"],
      source: data,
    },
    xAxis: { type: "category" },
    yAxis: { minInterval: 1 },
    series: [
      { type: "bar", barWidth: 20 },
      { type: "bar", barWidth: 20 },
    ],
  };
  return option;
};
// 垂直柱状图
export const getVerticalBarChartOption = function(data, name) {
  const option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        // 坐标轴指示器，坐标轴触发有效
        type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
      },
    },
    grid: {
      left: 0,
      right: 0,
      top: 20,
      bottom: 0,
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        inverse: true,
        data: data.map((item) => item.name),
        axisLabel: {
          color: "rgba(0,0,0,0.4)",
        },
        axisTick: {
          alignWithLabel: true,
          lineStyle: {
            color: "#E7E7E7",
          },
        },
        axisLine: {
          lineStyle: {
            color: "#E7E7E7",
          },
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        minInterval: 1,
        splitLine: {
          lineStyle: {
            type: "dashed",
          },
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
      },
    ],
    series: [
      {
        name,
        type: "bar",
        barWidth: 58,
        data,
        label: {
          show: true,
          position: "top",
          formatter: "{c}",
          color: "rgba(0,0,0,0.9)",
          fontWeight: "bold",
        },
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 0.7, [
            {
              offset: 0,
              color: "#FFD9AC",
            },
            {
              offset: 1,
              color: "#FF8C00",
            },
          ]),
          opacity: 0.8,
        },
      },
    ],
  };
  return option;
};
// 满意度调查饼图
export const getSatisfactionPieChartOption = function(data) {
  const color = ["#07C160", "#42B8EB", "#FDCB05", "#FF4800"];
  const option = {
    tooltip: {
      trigger: "item",
    },
    grid: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      containLabel: true,
    },
    legend: {
      orient: "vertical",
      align: "left",
      left: "center",
      bottom: "bottom",
      itemWidth: 12,
      itemHeight: 4,
      itemGap: 20,
    },
    color,
    series: [
      {
        name: "满意度",
        type: "pie",
        radius: "90%",
        center: ["50%", "45%"],
        data,
        label: {
          show: false,
        },
      },
    ],
  };
  return option;
};
// 考勤统计饼图
export const getAttendancePieChartOption = function(data, total, subtext = "应到人数") {
  const option = {
    color: ["#FF8C00", "#FF4800", "#FDCB05"],
    grid: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      containLabel: true,
    },
    legend: {
      orient: "vertical",
      align: "left",
      left: "center",
      bottom: "bottom",
      itemWidth: 12,
      itemHeight: 4,
      itemGap: 30,
    },
    title: {
      text: total,
      subtext,
      left: "center",
      top: "33%",
      textStyle: {
        color: "rgba(0, 0, 0, 0.9)",
        fontSize: 36,
        align: "center",
      },
      subtextStyle: {
        color: "rgba(0, 0, 0, 0.6)",
        padding: [12, 0],
        fontSize: 12,
      },
    },
    series: [
      {
        name: "数量",
        type: "pie",
        center: ["50%", "40%"],
        radius: ["62%", "80%"],
        emphasis: {
          scale: false,
        },
        label: {
          position: "inside",
          formatter: function(params) {
            let total = 0;
            let percent = 0;
            data.forEach(function(value) {
              total += value.value;
            });
            percent = ((params.value / total || 0) * 100)?.toFixed(1);
            return "{white|" + percent + "%}";
          },
          rich: {
            white: {
              color: "#fff",
              align: "center",
              fontSize: 12,
              padding: [21, 0],
            },
          },
        },
        labelLine: {
          show: false,
        },
        data,
      },
    ],
  };
  return option;
};
// 报警统计柱状图
export const getAlarmsBarChartOption = function(data) {
  const yData = data.map((item) => item.name);
  const values = data.map((item) => item.value);
  const max = Math.round(Math.max(...values) * 1.2);
  const defaultData = new Array(values.length).fill(max);
  const option = {
    grid: {
      left: 0,
      right: 0,
      bottom: 0,
      top: "5%",
      containLabel: true,
    },
    tooltip: {
      show: false,
    },
    xAxis: {
      show: false,
      type: "value",
      max,
    },
    yAxis: [
      {
        type: "category",
        inverse: true,
        axisLabel: {
          show: true,
          color: "rgba(0, 0, 0, 0.6)",
          fontSize: 14,
        },
        splitLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        data: yData,
      },
      {
        type: "category",
        inverse: true,
        axisTick: "none",
        axisLine: "none",
        show: true,
        axisLabel: {
          color: "rgba(0, 0, 0, 0.9)",
          fontSize: 14,
        },
        data: values,
      },
    ],
    series: [
      {
        name: "完成率",
        type: "bar",
        zlevel: 1,
        silent: true,
        itemStyle: {
          borderRadius: 0,
          color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
            {
              offset: 0,
              color: "#FF4800",
            },
            {
              offset: 1,
              color: "#FFE399",
            },
          ]),
        },
        barWidth: 12,
        data,
      },
      {
        name: "背景",
        type: "bar",
        silent: true,
        barWidth: 20,
        barGap: "-130%",
        data: defaultData,
        itemStyle: {
          color: "#F8F3E7",
          barBorderRadius: 0,
        },
      },
    ],
  };
  return option;
};
// 客流统计折线图
export const getVisitorLineChartOption = function(data) {
  const xData = data.map((item) => item.name);
  const option = {
    tooltip: {
      trigger: "axis",
    },
    grid: {
      right: 0,
      top: "5%",
      bottom: "5%",
      left: "2%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: xData,
    },
    yAxis: {
      type: "value",
      minInterval: 1,
    },
    series: [
      {
        data,
        type: "line",
        symbol: "circle",
        symbolSize: 8,
        // smooth: true,
        itemStyle: {
          color: "#FF8C00",
          borderWidth: 1,
          borderColor: "#fff",
          shadowColor: "#FF8C00",
          shadowBlur: 2,
        },
        lineStyle: {
          color: "#FF8C00",
          width: 1,
        },
      },
    ],
  };
  return option;
};
