<template>
  <a-row :gutter="gutter" type="flex">
    <a-col :span="6"></a-col>
    <a-col :span="6">
      <BoxCard title="部门人数" :sub-title="countInfo.total" class="height mini">
        <div class="department-info-list">
          <div class="department-info-item">
            <div class="info-item-title">僧众</div>
            <div class="info-item-value">{{ countInfo.buddhistMonks }}</div>
          </div>
          <div class="department-info-item">
            <div class="info-item-title">职工</div>
            <div class="info-item-value">{{ countInfo.workers }}</div>
          </div>
        </div>
      </BoxCard>
    </a-col>
    <a-col :span="6">
      <BoxCard title="今日汇报" class="height mini">
        <div class="department-info-list">
          <div class="department-info-item">
            <div class="info-item-title">已汇报</div>
            <div class="info-item-value">{{ reportInfo.reported }}</div>
          </div>
          <div class="department-info-item">
            <div class="info-item-title">未汇报</div>
            <div class="info-item-value">{{ reportInfo.unreported }}</div>
          </div>
        </div>
      </BoxCard>
    </a-col>
    <a-col :span="6">
      <BoxCard title="报修统计" class="height mini">
        <div class="department-info-list">
          <div class="department-info-item">
            <div class="info-item-title">本月</div>
            <div class="info-item-value">{{ repairInfo.month }}</div>
          </div>
          <div class="department-info-item">
            <div class="info-item-title">本年</div>
            <div class="info-item-value">{{ repairInfo.year }}</div>
          </div>
        </div>
      </BoxCard>
    </a-col>
    <a-col :span="6">
      <BoxCard
          title="人员去向"
          class="height normal"
          show-query
          :options="attendanceOptions"
          :active.sync="attendanceActive"
      >
        <chart :option="attendanceOption" class="chart-box" />
      </BoxCard>
    </a-col>
    <a-col :span="6">
      <BoxCard
          title="用餐人数"
          :sub-title="diningCount"
          show-query
          :options="timeOptions"
          :active.sync="diningActive"
          class="height normal"
      >
        <template #panel>
          <div class="panel-info dining-info">
            <div class="panel-info-item">
              <span class="panel-info-text">用餐总人数</span>
              <span class="panel-info-count">{{ diningCount }}</span>
            </div>
          </div>
        </template>
        <chart :option="diningOption" class="chart-box" />
      </BoxCard>
    </a-col>
    <a-col :span="6">
      <BoxCard
          title="响应效率"
          show-table
          :columns="responseColumns"
          :data="responseData"
          class="height normal"
          show-query
          :options="conditionOptions"
          :active.sync="responseActive"
      ></BoxCard>
    </a-col>
    <a-col :span="6">
      <BoxCard
          title="部门设备"
          show-table
          :columns="deviceColumns"
          :data="deviceData"
          class="height normal"
      ></BoxCard>
    </a-col>
    <a-col :span="6">
      <BoxCard hide-title class="height normal special-box">
        <div class="full-content flex-box vertical">
          <BoxCard
              title="设备维护保养记录"
              show-table
              show-table-header-top-line
              :columns="operateColumns"
              :data="maintainData"
              small
              show-query
              :options="conditionOptions"
              :active.sync="maintainActive"
              class="flex-grow small-card"
          >
            <template #panel>
              <div class="panel-total no-margin">{{ maintainCount }}</div>
            </template>
          </BoxCard>
          <BoxCard
              title="斋堂日常卫生检查"
              show-table
              show-table-header-top-line
              :columns="operateColumns"
              :data="healthData"
              small
              show-query
              :options="conditionOptions"
              :active.sync="healthActive"
              class="flex-grow small-card"
          >
            <template #panel>
              <div class="panel-total no-margin">{{ healthCount }}</div>
            </template>
          </BoxCard>
        </div>
      </BoxCard>
    </a-col>
    <a-col :span="6">
      <BoxCard hide-title class="height normal special-box">
        <div class="full-content flex-box vertical">
          <BoxCard
              title="食堂消毒记录"
              show-table
              show-table-header-top-line
              :columns="operateColumns"
              :data="disinfectData"
              small
              show-query
              :options="conditionOptions"
              :active.sync="disinfectActive"
              class="flex-grow small-card"
          >
            <template #panel>
              <div class="panel-total no-margin">{{ disinfectCount }}</div>
            </template>
          </BoxCard>
          <BoxCard
              title="设备工具清洗记录"
              show-table
              show-table-header-top-line
              :columns="operateColumns"
              :data="cleanData"
              small
              show-query
              :options="conditionOptions"
              :active.sync="cleanActive"
              class="flex-grow small-card"
          >
            <template #panel>
              <div class="panel-total no-margin">{{ cleanCount }}</div>
            </template>
          </BoxCard>
        </div>
      </BoxCard>
    </a-col>
    <a-col :span="12">
      <Project :value="value" :deptList="deptList" class="height normal" />
    </a-col>
    <!-- <a-col :span="6">
      <BoxCard
        title="斋堂日常卫生检查"
        :sub-title="healthCount"
        show-table
        :columns="operateColumns"
        :data="healthData"
        show-query
        :options="conditionOptions"
        :active.sync="healthActive"
        class="height normal"
      ></BoxCard>
    </a-col>
    <a-col :span="6">
      <BoxCard
        title="设备工具清洗记录"
        :sub-title="cleanCount"
        show-table
        :columns="operateColumns"
        :data="cleanData"
        show-query
        :options="conditionOptions"
        :active.sync="cleanActive"
        class="height normal"
      ></BoxCard>
    </a-col>
    <a-col :span="6">
      <BoxCard
        title="设备工具保养记录"
        :sub-title="maintainCount"
        show-table
        :columns="operateColumns"
        :data="maintainData"
        show-query
        :options="conditionOptions"
        :active.sync="maintainActive"
        class="height normal"
      ></BoxCard>
    </a-col>
    <a-col :span="6">
      <BoxCard
        title="食堂消毒记录"
        :sub-title="disinfectCount"
        show-table
        :columns="operateColumns"
        :data="disinfectData"
        show-query
        :options="conditionOptions"
        :active.sync="disinfectActive"
        class="height normal"
      ></BoxCard>
    </a-col> -->
  </a-row>
</template>

<script>
import BoxCard from "./components/box-card.vue";
import { capitalizeFirstLetter } from "@/common/js/tool";
import { timeOptions, attendanceOptions, getVerticalBarChartOption, getAttendancePieChartOption } from "./constant";
import { getTemple } from "@/common/js/storage";
import Project from "./layouts/Project.vue";

const KEYS = ["attendance", "dining"];

export default {
  name: "Refectory",
  components: { BoxCard, Project },
  props: {
    deptList: { type: Array },
    value: { type: Number },
  },
  data() {
    const options = {};
    KEYS.forEach((key) => (options[`${key}Option`] = {}));
    return {
      deptName: "斋堂",
      gutter: [16, 16],
      countInfo: {
        total: 0, // 全寺总人数
        buddhistMonks: 0, // 僧众总人数
        workers: 0, // 职工总人数
      },
      // 今日汇报
      reportInfo: {
        reported: 0, // 已汇报
        unreported: 0, // 未汇报
      },
      // 报修统计
      repairInfo: {
        month: 0, // 本月
        year: 0, // 本年
      },
      ...options,
      attendanceActive: 0,
      attendanceOptions,
      diningCount: 99, // 接待人数
      diningActive: 1,
      timeOptions,
      conditionOptions: timeOptions.slice(1),
      responseActive: 2,
      responseColumns: [
        {
          title: "排名",
          dataIndex: "rank",
          customRender: (text, record, index) => <div class={`rank ${index < 3 ? "top" : ""}`}>{++index}</div>,
          align: "center",
        },
        { title: "姓名", dataIndex: "name", align: "center" },
        { title: "平均时长(h)", dataIndex: "average_duration", align: "center", width: 120 },
      ],
      responseData: [],
      deviceColumns: [
        { title: "设备名称", dataIndex: "device_name", align: "center", customRender: (text) => text?.name },
        { title: "保管人", dataIndex: "name", align: "center" },
      ],
      deviceData: [],
      operateColumns: [
        { title: "操作日期", dataIndex: "create_time", align: "center" },
        {
          title: "操作人",
          dataIndex: "wx_user_id",
          align: "center",
          customRender: (text) => <open-data type="userName" openid={text} />,
        },
      ],
      /* 斋堂日常卫生检查 */
      healthCount: 0,
      healthActive: 2,
      healthData: [],
      /* 设备工具清洗记录 */
      cleanCount: 99,
      cleanActive: 2,
      cleanData: [],
      /* 设备工具保养记录 */
      maintainCount: 99,
      maintainActive: 2,
      maintainData: [],
      /* 食堂消毒记录 */
      disinfectCount: 99,
      disinfectActive: 2,
      disinfectData: [],
    };
  },
  computed: {
    dept: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {
    attendanceActive() {
      this.getAttendanceChart();
    },
    diningActive() {
      this.getDiningChart();
    },
    responseActive() {
      this.setResponseData();
    },
    healthActive() {
      this.setHealth();
    },
    cleanActive() {
      this.setClean();
    },
    maintainActive() {
      this.setMaintain();
    },
    disinfectActive() {
      this.setDisinfect();
    },
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.$emit("loading");
      let params = {};
      let temple = getTemple();
      if (temple) {
        params.temple_id = temple;
      }
      this.$axios({
        url: "/admin/dept-datas/get-zhai-tang",
        method: "GET",
        params,
        noTempleFilter: true,
      })
        .then((res) => {
          this.info = res;
          this.setCountInfo();
          this.setReportInfo();
          this.setRepairInfo();
          this.setResponseData();
          this.setDeviceData();
          this.setHealth();
          this.setClean();
          this.setMaintain();
          this.setDisinfect();
          this.init();
        })
        .finally(() => {
          this.$emit("loaded")
        });
    },
    // 全寺人数
    setCountInfo() {
      const { total_num, sz_num, zg_num } = this.info?.total_num || {};
      this.countInfo.total = total_num || 0;
      this.countInfo.buddhistMonks = sz_num || 0;
      this.countInfo.workers = zg_num || 0;
    },
    // 今日汇报
    setReportInfo() {
      const { reported, unreported } = this.info?.today_report || {};
      this.reportInfo.reported = reported || 0;
      this.reportInfo.unreported = unreported || 0;
    },
    // 报修统计
    setRepairInfo() {
      const { month, year } = this.info?.repair_report || {};
      this.repairInfo.month = month || 0;
      this.repairInfo.year = year || 0;
    },
    // 响应效率
    setResponseData() {
      const { month, year } = this.info?.efficiency || {};
      switch (this.responseActive) {
        case 2: // 本月
          this.responseData = month || [];
          break;
        case 3: // 本年
          this.responseData = year || [];
          break;
        default:
          this.responseData = [];
      }
    },
    // 部门设备
    setDeviceData() {
      this.deviceData = this.info?.dept_device || [];
    },
    init() {
      this.getAllChart(KEYS);
    },
    // 获取所有图表
    getAllChart(keys) {
      keys?.forEach((key) => {
        key = capitalizeFirstLetter(key);
        this[`get${key}Chart`]();
      });
    },
    // 考勤
    getAttendanceChart() {
      const kq = this.info?.kq || [];
      const count = kq[this.attendanceActive]?.count || []; // count: ["应到人数"， "在职", "请假", "外派"]
      const data = [
        { name: "在职", value: 0 },
        { name: "请假", value: 0 },
        { name: "外派", value: 0 },
      ];
      const total = count[0] || 0;
      data.forEach((item, index) => {
        item.value = count[index + 1] || 0;
      });
      this.attendanceOption = getAttendancePieChartOption(data, total);
    },
    // 用餐
    getDiningChart() {
      const {
        month_num,
        month_self_service,
        month_table_service,
        self_service_num,
        table_service_num,
        today_num,
        year_num,
        year_self_service,
        year_table_service,
      } = this.info?.reception || {};
      const data = [
        { name: "桌餐", value: 0 },
        { name: "自助餐", value: 0 },
      ];
      switch (this.diningActive) {
        case 1: // 今日
          data[0].value = table_service_num || 0;
          data[1].value = self_service_num || 0;
          this.diningCount = today_num || 0;
          break;
        case 2: // 本月
          data[0].value = month_table_service || 0;
          data[1].value = month_self_service || 0;
          this.diningCount = month_num || 0;
          break;
        case 3: // 本年
          data[0].value = year_table_service || 0;
          data[1].value = year_self_service || 0;
          this.diningCount = year_num || 0;
          break;
        default:
          data[0].value = 0;
          data[1].value = 0;
          this.diningCount = 0;
      }
      this.diningOption = getVerticalBarChartOption(data, "用餐");
    },
    // 斋堂日常卫生检查
    setHealth() {
      const { month, month_count, year, year_count } = this.info?.sanitary_check || {};
      switch (this.healthActive) {
        case 2: // 本月
          this.healthData = month || [];
          this.healthCount = month_count || 0;
          break;
        case 3: // 本年
          this.healthData = year || [];
          this.healthCount = year_count || 0;
          break;
        default:
          this.healthData = [];
          this.healthCount = 0;
      }
    },
    // 设备工具清洗记录
    setClean() {
      const { month, month_count, year, year_count } = this.info?.device_clean || {};
      switch (this.cleanActive) {
        case 2: // 本月
          this.cleanData = month || [];
          this.cleanCount = month_count || 0;
          break;
        case 3: // 本年
          this.cleanData = year || [];
          this.cleanCount = year_count || 0;
          break;
        default:
          this.cleanData = [];
          this.cleanCount = 0;
      }
    },
    // 设备工具保养记录
    setMaintain() {
      const { month, month_count, year, year_count } = this.info?.device_maintenance || {};
      switch (this.maintainActive) {
        case 2: // 本月
          this.maintainData = month || [];
          this.maintainCount = month_count || 0;
          break;
        case 3: // 本年
          this.maintainData = year || [];
          this.maintainCount = year_count || 0;
          break;
        default:
          this.maintainData = [];
          this.maintainCount = 0;
      }
    },
    // 食堂消毒记录
    setDisinfect() {
      const { month, month_count, year, year_count } = this.info?.disinfect || {};
      switch (this.disinfectActive) {
        case 2: // 本月
          this.disinfectData = month || [];
          this.disinfectCount = month_count || 0;
          break;
        case 3: // 本年
          this.disinfectData = year || [];
          this.disinfectCount = year_count || 0;
          break;
        default:
          this.disinfectData = [];
          this.disinfectCount = 0;
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import url(../../../common/less/department.less);

.dining-info {
  border-bottom: 1px solid #e7e7e7;
}
</style>
