<template>
  <a-row :gutter="gutter" type="flex">
    <a-col :span="6"></a-col>
    <a-col :span="6">
      <BoxCard title="总人数" :subTitle="countInfo.total" class="height mini">
        <div class="department-info-list">
          <div class="department-info-item">
            <div class="info-item-title">僧众总人数</div>
            <div class="info-item-value">{{ countInfo.buddhistMonks }}</div>
          </div>
          <div class="department-info-item">
            <div class="info-item-title">职工总人数</div>
            <div class="info-item-value">{{ countInfo.workers }}</div>
          </div>
        </div>
      </BoxCard>
    </a-col>
    <a-col :span="6">
      <BoxCard title="今日汇报" class="height mini">
        <div class="department-info-list">
          <div class="department-info-item">
            <div class="info-item-title">已汇报</div>
            <div class="info-item-value">{{ reportInfo.reported }}</div>
          </div>
          <div class="department-info-item">
            <div class="info-item-title">未汇报</div>
            <div class="info-item-value">{{ reportInfo.unreported }}</div>
          </div>
        </div>
      </BoxCard>
    </a-col>
    <a-col :span="6">
      <BoxCard
          title="审批统计"
          class="height mini"
          show-query
          :options="timeWeekOptions"
          :active.sync="approvalActive"
      >
        <div class="department-info-list">
          <div class="department-info-item">
            <div class="info-item-title">动火审批</div>
            <div class="info-item-value">{{ approvalInfo.hotwork }}</div>
          </div>
          <div class="department-info-item">
            <div class="info-item-title">隐患治理</div>
            <div class="info-item-value">{{ approvalInfo.pitfall }}</div>
          </div>
        </div>
      </BoxCard>
    </a-col>
    <a-col :span="6">
      <BoxCard
          title="人员去向"
          class="height normal"
          show-query
          :options="attendanceOptions"
          :active.sync="attendanceActive"
      >
        <chart :option="attendanceOption" class="chart-box" />
      </BoxCard>
    </a-col>
    <a-col :span="6">
      <BoxCard title="客流统计" class="height normal">
        <template #query>
          <div class="visitor-statistics-title" @click="showVisitor">实时客流统计</div>
        </template>
        <chart :option="visitorOption" class="chart-box" />
      </BoxCard>
    </a-col>
    <a-col :span="6">
      <BoxCard
          title="部门设备"
          show-table
          :columns="deviceColumns"
          :data="deviceData"
          class="height normal"
      ></BoxCard>
    </a-col>
    <a-col :span="6">
      <BoxCard
          title="入寺车辆"
          show-table
          show-table-header-top-line
          :columns="carColumns"
          :data="carData"
          class="height normal"
      >
        <template #panel>
          <div class="panel-total">{{ carCount }}</div>
        </template>
      </BoxCard>
    </a-col>
    <a-col :span="12">
      <Project :value="value" :deptList="deptList" class="height normal" />
    </a-col>
    <a-col :span="6">
      <BoxCard hide-title class="height normal special-box">
        <div class="full-content flex-box vertical">
          <BoxCard
              title="巡查记录"
              show-table
              :columns="patrolColumns"
              :data="patrolData"
              small
              class="flex-grow small-card"
          >
          </BoxCard>
          <BoxCard
              title="报警统计"
              show-table
              show-table-header-top-line
              :columns="alarmsColumns"
              :data="alarmsData"
              small
              show-query
              :options="timeOptions"
              :active.sync="alarmsActive"
              class="flex-grow small-card"
          >
            <template #panel>
              <div class="panel-info-item">
                <span class="panel-info-text">报警次数</span>
                <span class="panel-info-count">{{ alarmsCount }}</span>
              </div>
            </template>
          </BoxCard>
        </div>
      </BoxCard>
    </a-col>
    <a-col :span="6">
      <BoxCard hide-title class="height normal special-box">
        <div class="full-content flex-box vertical">
          <BoxCard
              title="消防演习"
              show-table
              :columns="exerciseColumns"
              :data="exerciseData"
              small
              class="flex-grow small-card"
          >
          </BoxCard>
          <BoxCard
              title="消防培训"
              show-table
              :columns="trainColumns"
              :data="trainData"
              small
              class="flex-grow small-card"
          >
          </BoxCard>
        </div>
      </BoxCard>
    </a-col>
    <VisitorModal v-model="visible" :info="info" />
  </a-row>
</template>

<script>
import BoxCard from "./components/box-card.vue";
import {
  timeOptions,
  timeWeekOptions,
  getAttendancePieChartOption,
  getVerticalBarChartOption,
  attendanceOptions,
} from "./constant";
import { capitalizeFirstLetter, getKeyTitle } from "@/common/js/tool";
import { getTemple } from "@/common/js/storage";
import { levelOptions, warningOptions, postOptions } from "@/common/constant/safe";
import Project from "./layouts/Project.vue";
import VisitorModal from "./layouts/VisitorModal.vue";

const KEYS = ["attendance", "visitor"];
// 消防演习类型
const exerciseOptions = [
  { label: "内部演习", value: 1 },
  { label: "统一演习", value: 2 },
];

export default {
  name: "Security",
  components: {  BoxCard, Project, VisitorModal },
  props: {
    deptList: { type: Array },
    value: { type: Number },
  },
  data() {
    const options = {};
    KEYS.forEach((key) => (options[`${key}Option`] = {}));
    return {
      deptName: "监院四(安保)、安保部",
      gutter: [16, 16],
      info: null,
      countInfo: {
        total: 0, // 全寺总人数
        buddhistMonks: 0, // 僧众总人数
        workers: 0, // 职工总人数
      },
      // 今日汇报
      reportInfo: {
        reported: 0, // 已汇报
        unreported: 0, // 未汇报
      },
      /* 审批统计 */
      approvalActive: 1,
      approvalInfo: {
        hotwork: 0, // 动火审批
        pitfall: 0, // 隐患治理
      },
      timeWeekOptions,
      attendanceActive: 0,
      attendanceOptions,
      deviceColumns: [
        { title: "设备名称", dataIndex: "device_name", align: "center", customRender: (text) => text?.name },
        { title: "保管人", dataIndex: "name", align: "center" },
      ],
      deviceData: [],
      /* 入寺车辆 */
      carColumns: [
        { title: "时间", dataIndex: "carAccessTime", align: "center" },
        { title: "车牌号", dataIndex: "carNumber", align: "center" },
        {
          title: "门岗",
          dataIndex: "position",
          align: "center",
          customRender: (text) => <span>{getKeyTitle(postOptions, text, "value", "label")}</span>,
        },
      ],
      carData: [],
      carCount: 0,
      trainColumns: [
        { title: "培训名称", dataIndex: "name", align: "center" },
        { title: "参加人数", dataIndex: "join_num", align: "center" },
        { title: "岗前培训", dataIndex: "train", align: "center" },
      ],
      trainData: [],
      patrolColumns: [
        { title: "上报时间", dataIndex: "date", align: "center", width: 100 },
        { title: "地点", dataIndex: "location", align: "center" },
        {
          title: "情况",
          dataIndex: "status",
          align: "center",
          customRender: (text) => <span style="color: #07C160">{text}</span>,
        },
        // {
        //   title: "图片",
        //   dataIndex: "img",
        //   align: "center",
        //   customRender: (text) => {
        //     const previewImg = () => {
        //       if (typeof text === "string") {
        //         text = text.split(",").map((v, index) => {
        //           return {
        //             url: v,
        //             title: "图片" + ++index,
        //           };
        //         });
        //       }
        //       this.$store.dispatch("showImgList", text);
        //     };
        //     return <a-icon type="search" style={{ color: "#FF8C00" }} onClick={previewImg} />;
        //   },
        // },
      ],
      patrolData: [],
      exerciseColumns: [
        { title: "演习名称", dataIndex: "name", align: "center" },
        {
          title: "类型",
          dataIndex: "type",
          align: "center",
          customRender: (text) => <span>{getKeyTitle(exerciseOptions, text, "value", "label")}</span>,
        },
        {
          title: "演习图片",
          dataIndex: "img",
          align: "center",
          customRender: (text) => {
            const previewImg = () => {
              if (text) {
                text = JSON.parse(text).map((item) => {
                  return {
                    url: item.url,
                    title: item.fileName,
                  };
                });
              }
              this.$store.dispatch("showImgList", text);
            };
            return <a-icon type="search" style={{ color: "#FF8C00" }} onClick={previewImg} />;
          },
        },
      ],
      exerciseData: [],
      alarmsCount: 0,
      alarmsColumns: [
        { title: "报警时间", dataIndex: "create_time", align: "center" },
        {
          title: "预警类型",
          dataIndex: "device_type",
          align: "center",
          customRender: (text) =>
            text === "0" ? "监控" : <span>{getKeyTitle(warningOptions, text, "value", "label")}</span>,
        },
        {
          title: "预警级别",
          dataIndex: "level",
          align: "center",
          customRender: (text) => <span>{getKeyTitle(levelOptions, text, "value", "label")}</span>,
        },
      ],
      alarmsData: [],
      alarmsActive: 1,
      timeOptions,
      ...options,
      visitorOptions: [
        { label: "日", value: 1 },
        { label: "月", value: 2 },
      ],
      visitorActive: 1,
      visible: false,
    };
  },
  computed: {
    dept: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {
    approvalActive() {
      this.setApprovalInfo();
    },
    attendanceActive() {
      this.getAttendanceChart();
    },
    alarmsActive() {
      this.setAlarmsCount();
    },
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.$emit("loading");
      let params = {};
      let temple = getTemple();
      if (temple) {
        params.temple_id = temple;
      }
      this.$axios({
        url: "/admin/dept-datas/get-security-data",
        method: "GET",
        params,
        noTempleFilter: true,
      })
        .then((res) => {
          this.info = res;
          this.setCountInfo();
          this.setReportInfo();
          this.setApprovalInfo();
          this.setDeviceData();
          this.setCarData();
          this.setPatrolData();
          this.setAlarmsCount();
          this.setAlarmsData();
          this.setExerciseData();
          this.setTrainData();
          this.init();
        })
        .finally(() => {
          this.$emit("loaded")
        });
    },
    // 全寺人数
    setCountInfo() {
      const { total_num, sz_num, zg_num } = this.info?.total_num || {};
      this.countInfo.total = total_num || 0;
      this.countInfo.buddhistMonks = sz_num || 0;
      this.countInfo.workers = zg_num || 0;
    },
    // 今日汇报
    setReportInfo() {
      const { reported, unreported } = this.info?.today_report || {};
      this.reportInfo.reported = reported || 0;
      this.reportInfo.unreported = unreported || 0;
    },
    // 审批统计
    setApprovalInfo() {
      const { month, week, year } = this.info?.sptj || {};
      switch (this.approvalActive) {
        case 1: // 本周
          this.approvalInfo.hotwork = week?.[0] || 0;
          this.approvalInfo.pitfall = week?.[1] || 0;
          break;
        case 2: // 本月
          this.approvalInfo.hotwork = month?.[0] || 0;
          this.approvalInfo.pitfall = month?.[1] || 0;
          break;
        case 3: // 本年
          this.approvalInfo.hotwork = year?.[0] || 0;
          this.approvalInfo.pitfall = year?.[1] || 0;
          break;
        default:
          this.approvalInfo.hotwork = 0;
          this.approvalInfo.pitfall = 0;
      }
    },
    // 部门设备
    setDeviceData() {
      this.deviceData = this.info?.dept_device || [];
    },
    // 入寺车辆
    setCarData() {
      const { count, record } = this.info?.cljl || {};
      this.carData = record || [];
      this.carCount = count[0] || 0;
    },
    // 巡查记录
    setPatrolData() {
      // this.patrolData = this.info?.xcjl || [];
      // 数据来自大华（暂无），目前写死
      this.patrolData = [
        {
          id: 1,
          date: "2024-06-07 07:52",
          location: "华严殿",
          status: "正常",
        },
        {
          id: 2,
          date: "2024-06-05 12:12",
          location: "华严殿",
          status: "正常",
        },
        {
          id: 3,
          date: "2024-06-04 09:30",
          location: "华严殿",
          status: "正常",
        },
      ];
    },
    // 报警次数
    setAlarmsCount() {
      const { count } = this.info?.bjtj || {};
      const alarmsCount = count?.[this.alarmsActive - 1] || 0;
      this.alarmsCount = alarmsCount;
    },
    // 报警统计
    setAlarmsData() {
      const { data } = this.info?.bjtj || {};
      this.alarmsData = data || [];
    },
    // 消防演习
    setExerciseData() {
      this.exerciseData = this.info?.xfyx || [];
    },
    // 消防培训
    setTrainData() {
      this.trainData = this.info?.xfpx || [];
    },
    init() {
      this.getAllChart(KEYS);
    },
    // 获取所有图表
    getAllChart(keys) {
      keys?.forEach((key) => {
        key = capitalizeFirstLetter(key);
        this[`get${key}Chart`]();
      });
    },
    // 人员去向
    getAttendanceChart() {
      const kq = this.info?.kq || [];
      const count = kq[this.attendanceActive]?.count || []; // count: ["应到人数"， "在职", "请假", "外派"]
      const data = [
        { name: "在职", value: 0 },
        { name: "请假", value: 0 },
        { name: "外派", value: 0 },
      ];
      const total = count[0] || 0;
      data.forEach((item, index) => {
        item.value = count[index + 1] || 0;
      });
      this.attendanceOption = getAttendancePieChartOption(data, total);
    },
    // 客流统计
    getVisitorChart() {
      const { now, all } = this.info?.rs || {};
      const data = [
        { name: "在寺人数", value: all || 0 },
        { name: "进寺人数", value: now || 0 },
      ];
      this.visitorOption = getVerticalBarChartOption(data);
    },
    // 展示实时客流统计弹框
    showVisitor() {
      this.visible = true;
    },
  },
};
</script>

<style lang="less" scoped>
@import url(../../../common/less/department.less);

.duty-count {
  color: var(--title_color);
  font-size: 28px;
  font-weight: 600;
  text-align: center;
}

.alarms-info {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  .alarms-title {
    color: var(--text_color);
    font-size: 14px;
  }
  .alarms-value {
    color: var(--title_color);
    font-size: 28px;
    font-weight: 600;
    line-height: 28px;
    margin-left: 12px;
  }
}
.alarms-info,
.alarms-chart {
  // flex: 0 0 50%;
}
.alarms-content {
  height: 100%;
}

.bar-box,
.table-box {
  flex: 0 0 50%;
}

.visitor-statistics-title {
  color: #ff8c00;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  cursor: pointer;
}
</style>
