<template>
  <a-spin :spinning="loading" class="flex-grow scroll-area">
    <div class="program-overview" v-if="deptList && deptList.length > 0">
      <div
          class="piece-box inner-content program-overview-top program-dept"
          :class="{'special-board': !!componentName, 'volunteer-board' : componentName === 'Volunteer'}">
        <a-tree-select
            v-model="dept"
            class="program-dept-select"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            :tree-data="deptList"
            placeholder="选择部门"
            v-if="true">
          <template v-slot:title="{value}">
            <open-data :openid="value" type="departmentName" />
          </template>
        </a-tree-select>
        <a-select
            v-model="dept"
            :options="deptList"
            placeholder="请选择部门"
            style="width: 100%"
            v-else
        ></a-select>
        <div class="program-dept-title">数据看板</div>
      </div>
      <component
          :is="componentName"
          :deptList="deptList"
          v-model="dept"
          @loading="loading = true"
          @loaded="loading = false"
          v-if="componentName"></component>
      <a-row :gutter="gutter" type="flex" v-else>
        <a-col :span="18">
          <a-row :gutter="gutter" type="flex">
            <a-col :span="24">
              <a-row :gutter="gutter" type="flex">
                <a-col :span="8">
                </a-col>
                <a-col :span="8">
                  <div class="piece-box inner-content program-overview-top program-staff-count">
                    <div class="program-count-item" v-for="(c, i) in staffCountList" :key="i">
                      <div class="program-count-title">{{ c.title }}</div>
                      <div class="program-count-num">{{ c.num }}</div>
                    </div>
                  </div>
                </a-col>
                <a-col :span="8">
                  <div class="piece-box inner-content program-overview-top program-staff-count">
                    <div class="program-count-item">
                      <div class="program-count-title">今日汇报</div>
                    </div>
                    <div class="program-count-item" v-for="(c, i) in reportCountList" :key="i">
                      <div class="program-count-title">{{ c.title }}</div>
                      <div class="program-count-num">{{ c.num }}</div>
                    </div>
                  </div>
                </a-col>
              </a-row>
            </a-col>
            <a-col :span="24">
              <a-row :gutter="gutter" type="flex">
                <a-col :span="8">
                  <department-attendance :number="countData.kqtjxz" />
                </a-col>
                <a-col :span="8">
                  <response-efficiency :number="countData.efficiency" />
                </a-col>
                <a-col :span="8">
                  <department-equipment :number="countData.dept_device" />
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </a-col>
        <a-col :span="6">
          <dynamic-notification :dept="dept" />
        </a-col>
        <a-col :span="24">
          <div class="piece-box inner-content">
            <a-tabs :active-key="key" @tabClick="handleClick">
              <a-tab-pane :key="1" tab="部门项目">
                <div class="program-table-content">
                  <department-program :dept="dept" style="height: 100%;" />
                </div>
              </a-tab-pane>
              <a-tab-pane :key="2" tab="个人任务">
                <div class="program-table-content">
                  <personnel-task :dept="dept" style="height: 100%;" />
                </div>
              </a-tab-pane>
            </a-tabs>
          </div>
        </a-col>
        <a-col :span="12" v-if="false">
          <div class="piece-box program-overview-bottom">
            <member-portrait style="height: 100%;" />
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="piece-box inner-content program-overview empty-box" style="height: 100%" v-else-if="deptList">
      <a-empty :image="emptyImage" description="您目前暂无负责的部门" />
    </div>
  </a-spin>
</template>

<script>
import { getTemple, getUser } from "@/common/js/storage";
import DepartmentAttendance from "./layouts/DepartmentAttendance";
import ResponseEfficiency from "./layouts/ResponseEfficiency";
import DepartmentEquipment from "./layouts/DepartmentEquipment";
import DynamicNotification from "./layouts/DynamicNotification";
import DepartmentProgram from "./layouts/DepartmentProgram";
import PersonnelTask from "./layouts/PersonnelTask";
import MemberPortrait from "./layouts/MemberPortrait";
import { Empty } from "ant-design-vue";
import Classroom from "./department/Classroom.vue";
import Volunteer from "./department/Volunteer.vue";
import Warehouse from "./department/Warehouse.vue";
import Security from "./department/Security.vue";
import Refectory from "./department/Refectory.vue";
import Engineering from "./department/Engineering.vue";
import {specialDeptList} from "@/permission/router/program";

export default {
  name: "ProgramOverview",
  components: {
    DepartmentAttendance,
    ResponseEfficiency,
    DepartmentEquipment,
    DynamicNotification,
    DepartmentProgram,
    PersonnelTask,
    MemberPortrait,
    Classroom,
    Volunteer,
    Warehouse,
    Security,
    Refectory,
    Engineering,
  },
  data() {
    return {
      user: null,
      dept: 0,
      key: 1,
      gutter: [16, 16],
      emptyImage: Empty.PRESENTED_IMAGE_SIMPLE,
      staffCountList: [
        { title: "总人数", num: 0 },
        { title: "僧众人数", num: 0 },
        { title: "职工人数", num: 0 },
      ],
      reportCountList: [
        { title: "已汇报", num: 0 },
        { title: "未汇报", num: 0 },
      ],
      loading: false,
      countData: {},
      deptList: null,
      specialDepts: [
        { label: "库房——物", value: "10", component: "Warehouse" },
        { label: "客堂", value: "100", component: "Classroom" },
        { label: "志工团", value: "129", component: "Volunteer" },
      ],
      componentName: null,
      authList: [],
      authValue: null,
    };
  },
  watch: {
    dept() {
      this.getComponentName();
    },
  },
  created() {
    this.getBoard();
  },
  methods: {
    getComponentName() {
      const target = specialDeptList.find((item) => item.dept_id === this.dept);
      this.componentName = target?.component;
      this.getDeptData();
    },
    getDeptTreeList(list) {
      return list.map(item => {
        const d = {
          key: item.id,
          value: item.id,
          scopedSlots: {
            title: 'title',
          },
        }
        if (item.children && item.children.length > 0) {
          d.children = this.getDeptTreeList(item.children);
        }
        return d;
      })
    },
    getBoard() {
      const user = getUser();
      this.user = user;
      // 超管看所有部门数据
      if (user.role === 1) {
        this.$store.dispatch("getBookList", true).then(list => {
          this.deptList = this.getDeptTreeList(list[0]?.children || []);
          if (this.deptList.length > 0) {
            this.dept = this.deptList[0].key;
          }
        });
      } else {
        const dept_board_new = user?.dept_board_new || [];
        this.deptList = dept_board_new.map((item) => {
          const target = specialDeptList.find((d) => d.dept_id === item.dept_id);
          return {
            key: item.dept_id,
            value: item.dept_id,
            scopedSlots: {
              title: 'title',
            },
            componentName: target?.component,
          };
        });
        if (this.deptList.length > 0) {
          this.dept = this.deptList[0].key;
        }
      }
    },
    handleClick(e) {
      this.key = e;
    },
    getDeptData() {
      if (this.componentName) {
        return;
      }
      if (this.dept) {
        this.loading = true;
        const url = `/admin/common/get-dept-datas?dept_id=${this.dept}&temple_id=${getTemple()}`;
        this.$axios(url).then((res) => {
          this.setDeptData(res);
          this.loading = false;
        });
      } else {
        this.loading = false;
      }
    },
    setDeptData(res) {
      const staffCountList = [...this.staffCountList];
      staffCountList[0].num = res.total_num.total_num * 1;
      staffCountList[1].num = res.total_num.buddhist_num * 1;
      staffCountList[2].num = res.total_num.workers_num * 1;
      this.staffCountList = staffCountList;
      const reportCountList = [...this.reportCountList];
      reportCountList[0].num = res.today_report.reported * 1;
      reportCountList[1].num = res.today_report.unreported * 1;
      this.reportCountList = reportCountList;
      this.countData = res;
    },
  },
};
</script>

<style lang="less">
.program-overview {
  position: relative;
  margin: 0 auto;
  width: 1440px;
}
.program-dept {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 8px;
  left: 0;
  width: 348px;
  z-index: 1;
  &.special-board {
    height: 162px;
    background-color: #fff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
  }
  &.volunteer-board {
    justify-content: flex-start;
    padding-top: 34px;
    padding-right: 220px;
    .program-dept-title {
      margin-top: 38px;
    }
  }
}
.program-overview-top {
  height: 120px;
}
.program-overview-bottom {
  height: 572px;
}
.program-dept-select {
  width: 100%;
}
.program-dept-name {
  margin-right: 8px;
  font-weight: bold;
}
.program-dept-title {
  margin-top: 12px;
  font-size: 16px;
  line-height: 24px;
}
.program-staff-count {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.program-count-item {
  flex: 1;
  min-width: 0;
  &:first-of-type {
    border-right: var(--border);
  }
}
.program-count-title {
  font-size: 16px;
  font-weight: bold;
}
.program-count-num {
  margin-top: 8px;
  color: @text-color-secondary;
}
.program-table-content {
  height: 480px;
}
.program-overview-loading {
  text-align: center;
  color: #ffce7e;
  font-size: 24px;
}
.empty-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
