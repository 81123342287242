<template>
  <a-modal v-model="visible" :footer="null" :width="800">
    <template #title>
      <div class="visitor-title">
        <span>实时客流统计</span>
        <a-radio-group v-model="visitorActive" size="small" class="query-radio-group">
          <a-radio-button :value="option.value" v-for="option in visitorOptions" :key="option.value">
            {{ option.label }}
          </a-radio-button>
        </a-radio-group>
      </div>
    </template>
    <chart :option="visitorOption" class="chart-box" />
  </a-modal>
</template>

<script>
import { timeOptions, getVisitorLineChartOption } from "../constant";

export default {
  name: "VisitorModal",
  props: {
    value: Boolean,
    info: Object,
  },
  data() {
    return {
      visitorOptions: timeOptions.slice(0, 2),
      visitorActive: 1,
      visitorOption: {},
    };
  },
  computed: {
    visible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {
    info() {
      this.getVisitorChart();
    },
    visitorActive() {
      this.getVisitorChart();
    },
  },
  created() {
    this.getVisitorChart();
  },
  methods: {
    // 获取近7天日期（MM-DD）,包含今天
    getPreviousSevenDaysFormatted() {
      const dates = [];
      const today = new Date();
      const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // 一天的毫秒数
      for (let i = 0; i <= 6; i++) {
        const date = new Date(today.getTime() - i * oneDayInMilliseconds);
        const formattedDate = `${date.getMonth() + 1}-${date.getDate()}`; // 月份加1是因为 getMonth() 返回的是基于0的索引
        dates.push(formattedDate);
      }
      return dates;
    },
    // 获取近期6个月的月份，若是本年，格式未MM，去年则为YY-MM
    getRecentMonthsFormatted() {
      let today = new Date();
      let months = [];
      months.push(today.pattern("yyyy-M"));
      for (let i = 0; i < 5; i++) {
        today.setMonth(today.getMonth() - 1);
        months.push(today.pattern("yyyy-M"));
      }
      return months;
    },
    // 客流统计
    getVisitorChart() {
      const { week_data, month_data } = this.info?.rs || {};
      let data = [];
      const dates = this.getPreviousSevenDaysFormatted();
      const monthes = this.getRecentMonthsFormatted();
      switch (this.visitorActive) {
        case 1: // 日
          data = dates
            .map((date, index) => {
              return {
                name: date,
                value: week_data?.[index] || 0,
              };
            })
            .reverse();
          break;
        case 2: // 月
          data = monthes
            .map((month, index) => {
              return {
                name: month,
                value: month_data?.[index] || 0,
              };
            })
            .reverse();
          break;
      }
      this.visitorOption = getVisitorLineChartOption(data);
    },
  },
};
</script>

<style lang="less" scoped>
.visitor-title {
  position: relative;
}
.query-radio-group {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.chart-box {
  width: 736px;
  height: 322px;
}

.ant-radio-button-wrapper {
  --main-color: #ff8c00;
  font-size: 12px;
}
.ant-radio-button-wrapper:hover {
  color: var(--main-color);
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  border-color: var(--main-color);
  color: var(--main-color);
  box-shadow: -1px 0 0 0 var(--main-color);
  &:hover {
    border-color: var(--main-color);
    color: var(--main-color);
    box-shadow: -1px 0 0 0 var(--main-color);
  }
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: var(--main-color);
  color: var(--main-color);
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: var(--main-color) !important;
}
</style>
