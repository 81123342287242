<template>
  <div class="flex-box vertical">
    <div class="data-title" v-if="!hideTitle">
      <a-form-model class="query-form" layout="inline">
        <a-form-model-item label="">
          <a-select
            style="width: 180px"
            placeholder="选择状态筛选"
            allowClear
            @change="getList"
            :options="status"
            v-model="form.status">
          </a-select>
        </a-form-model-item>
      </a-form-model>
      <div class="title-btn">
        <a-button @click="getList">查询</a-button>
      </div>
    </div>
    <div class="data-area">
      <a-table
        ref="dataTable"
        :columns="columns"
        row-key="id"
        :data-source="list"
        :loading="loading"
        :pagination="pagination"
        :scroll="{y: h}"
        @change="handleTableChange">
      </a-table>
    </div>
  </div>
</template>

<script>
    import {isEmpty} from "../../../common/js/tool";

    export default {
        name: "PersonnelTask",
        props: {
            dept: Number,
            type: Number,
            hideTitle: Boolean,
        },
        data() {
            return {
                list: [],
                columns: [
                    {title: '任务名称', dataIndex: 'title'},
                    {title: '任务负责人', dataIndex: 'user_id', customRender: text => <open-data type="userName" openid={text} />},
                    {title: '所属项目', dataIndex: 'plan', customRender: text => text ? text.title : '--'},
                    {title: '任务时间', dataIndex: 'time', customRender: (text, record) => `${record.begin_time} 至 ${record.end_time}`},
                    {title: '任务进度', dataIndex: 'status', customRender: text => ['进行中', '已完成', '已超时'][text] },
                ],
                form: {},
                loading: false,
                status: [
                    {key: 0, title: '进行中'},
                    {key: 1, title: '已完成'},
                    {key: 2, title: '已超时'},
                ],
                pagination: {
                    total: 0,
                    current: 1,
                    pageSize: 10,
                    showSizeChanger: true
                },
                h: 500,
            }
        },
        watch: {
            dept() {
                this.getList();
            },
            type(val) {
                this.form.status = val;
                this.updateList();
            }
        },
        created() {
            if (!isEmpty(this.type)) {
                this.form.status = this.type;
            }
            this.getList();
        },
        mounted() {
            this.setHeight();
            window.addEventListener("resize", this.setHeight, false);
        },
        beforeDestroy() {
            window.removeEventListener("resize", this.setHeight, false);
        },
        methods: {
            handleTableChange(pagination) {
                let pager = { ...this.pagination };
                pager.current = pagination.current;
                pager.pageSize = pagination.pageSize;
                this.pagination = pager;
                this.getList();
            },
            updateList() {
              this.pagination.page = 1;
              this.getList();
            },
            getList() {
                const dept = this.dept;
                if(!dept) return;
                let url = `/admin/plan-task?sort=-begin_time&expand=plan&dept_id=${dept}`;
                let {current, pageSize} = this.pagination;
                url += `&page=${current}&pageSize=${pageSize}`;
                let form = this.form;
                if(!isEmpty(form.status)) {
                    url += `&filter[status]=${form.status}`
                }
                this.$axios(url).then(res => {
                    let list = res.data;
                    this.list = list;
                    if(res.page) {
                        this.$set(this.pagination, 'total', res.page.totalCount);
                        this.$emit("total", res.page.totalCount)
                    }
                });
            },
            setHeight() {
                let dataTale = this.$refs.dataTable;
                if(dataTale) {
                    let area = this.$el.querySelector(".data-area");
                    this.h = area.clientHeight - 54 - 64;
                }
            },
        }
    }
</script>

<style scoped lang="less">
  .hr-apply-title {
    font-size: 16px;
    font-weight: 600;
  }
  .hr-apply-top {
    padding-bottom: 16px;
    border-bottom: var(--border);
  }
  .hr-apply-type {
    margin-top: 16px;
  }
</style>
