<template>
  <div class="piece-box inner-content response-efficiency ">
    <div class="piece-box-title flex-box align-center">
      <div class="flex-grow">响应效率</div>
      <piece-tab v-model="type" :tabs="timeTypes"></piece-tab>
    </div>
    <div class="response-efficiency-table">
      <div class="response-efficiency-row response-efficiency-head response-efficiency-deep">
        <div class="response-efficiency-td">排名</div>
        <div class="response-efficiency-td">姓名</div>
        <div class="response-efficiency-td">时长（h）</div>
      </div>
      <div class="response-efficiency-body" v-if="list.length > 0">
        <div class="response-efficiency-row" :class="{'response-efficiency -deep': i % 2 == 1}" v-for="(item, i) in list" :key="i">
          <div class="response-efficiency-td">{{ i + 1 }}</div>
          <div class="response-efficiency-td">{{ item.name }}</div>
          <div class="response-efficiency-td">{{ item.average_duration }}</div>
        </div>
      </div>
      <a-empty :image="emptyImage" v-else />
    </div>
  </div>
</template>

<script>
    import { Empty } from 'ant-design-vue';
    import pieceTab from "../../../components/piece-tab";

    export default {
        name: "ResponseEfficiency",
        components: {
            pieceTab
        },
        props: {
            number: Object
        },
        data() {
            return {
                type: "month",
                emptyImage: Empty.PRESENTED_IMAGE_SIMPLE
            }
        },
        computed: {
            timeTypes() {
                const res = [
                    {
                        key: "month",
                        title: "本月",
                        count: []
                    },
                    {
                        key: "year",
                        title: "本年",
                        count: []
                    },
                ];
                const number = this.number;
                if(number) {
                    res.forEach(item => {
                       item.count = number[item.key] || [];
                    });
                }
                return res;
            },
            list() {
                let res = [];
                const item = this.timeTypes.find(t => t.key == this.type);
                if(item) {
                    res = item.count;
                }
                return res;
            }
        }
    }
</script>

<style lang="less">
.response-efficiency-table {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  height: 249px;
}
.response-efficiency-body {
  flex: 1;
  min-height: 0;
  overflow: auto;
}
.response-efficiency-row {
  display: flex;
  align-items: center;
  text-align: center;
  height: 36px;
  &.response-efficiency-head {
    color: rgba(0,0,0,0.85);
    font-weight: bold;
  }
  &.response-efficiency-deep {
    background-color: @background-color-base;
  }
}
.response-efficiency-td {
  flex: 1;
}
</style>
