<template>
  <a-row :gutter="gutter" type="flex">
    <a-col :span="6"></a-col>
    <a-col :span="6">
      <BoxCard title="总人数" :subTitle="countInfo.total" class="height mini">
        <div class="department-info-list">
          <div class="department-info-item">
            <div class="info-item-title">僧众总人数</div>
            <div class="info-item-value">{{ countInfo.buddhistMonks }}</div>
          </div>
          <div class="department-info-item">
            <div class="info-item-title">职工总人数</div>
            <div class="info-item-value">{{ countInfo.workers }}</div>
          </div>
        </div>
      </BoxCard>
    </a-col>
    <a-col :span="6">
      <BoxCard title="今日汇报" class="height mini">
        <div class="department-info-list">
          <div class="department-info-item">
            <div class="info-item-title">已汇报</div>
            <div class="info-item-value">{{ reportInfo.reported }}</div>
          </div>
          <div class="department-info-item">
            <div class="info-item-title">未汇报</div>
            <div class="info-item-value">{{ reportInfo.unreported }}</div>
          </div>
        </div>
      </BoxCard>
    </a-col>
    <a-col :span="6">
      <BoxCard
          title="报修统计"
          class="height mini"
          show-query
          :options="conditionOptions"
          :active.sync="repairActive"
      >
        <div class="department-info-list">
          <div class="department-info-item">
            <div class="info-item-title">未派单</div>
            <div class="info-item-value">{{ repairInfo.unfinished }}</div>
          </div>
          <div class="department-info-item">
            <div class="info-item-title">已完成</div>
            <div class="info-item-value">{{ repairInfo.finished }}</div>
          </div>
        </div>
      </BoxCard>
    </a-col>
    <a-col :span="8">
      <BoxCard
          title="考勤统计"
          class="height normal"
          show-query
          :options="attendanceOptions"
          :active.sync="attendanceActive"
      >
        <chart :option="attendanceOption" class="chart-box" />
      </BoxCard>
    </a-col>
    <a-col :span="8">
      <BoxCard
          title="响应效率"
          show-table
          :columns="responseColumns"
          :data="responseData"
          class="height normal"
          show-query
          :options="conditionOptions"
          :active.sync="responseActive"
      ></BoxCard>
    </a-col>
    <a-col :span="6" v-if="false">
      <BoxCard
          title="部门设备"
          show-table
          :columns="deviceColumns"
          :data="deviceData"
          class="height normal"
      ></BoxCard>
    </a-col>
    <a-col :span="8">
      <BoxCard title="维保合同" show-table :columns="contractColumns" :data="contractData" class="height normal">
        <template v-slot:panel>
          <div class="department-info-list contract-list">
            <div class="department-info-item">
              <div class="info-item-title">即将到期(30天)</div>
              <div class="info-item-value">{{ contractInfo.expire }}</div>
            </div>
            <div class="department-info-item">
              <div class="info-item-title">有效</div>
              <div class="info-item-value">{{ contractInfo.effective }}</div>
            </div>
          </div>
        </template>
      </BoxCard>
    </a-col>
    <a-col :span="12">
      <Project :value="value" :deptList="deptList" class="height normal" />
    </a-col>
    <a-col :span="12">
      <BoxCard
        title="维保单"
        show-query
        class="height normal"
        :active.sync="maintenanceActive"
        :options="maintenanceOptions"
      >
        <a-table
          ref="dataTable"
          :columns="orderColumns"
          :data-source="orderData"
          row-key="id"
          bordered
          size="small"
          :pagination="orderPagination"
          @change="tableChange($event, 'order')"
          v-if="maintenanceActive === 0"
        />
        <a-table
          ref="dataTable"
          :columns="recordColumns"
          :data-source="recordData"
          row-key="id"
          bordered
          size="small"
          :scroll="{x: 'max-content'}"
          :pagination="recordPagination"
          @change="tableChange($event, 'record')"
          v-else
        />
      </BoxCard>
    </a-col>
  </a-row>
</template>

<script>
import BoxCard from "./components/box-card.vue";
import Project from "./layouts/Project.vue";
import { timeOptions, statusOptions, attendanceOptions, getAttendancePieChartOption } from "./constant";
import { capitalizeFirstLetter, getDate } from "@/common/js/tool";
import { getTemple } from "@/common/js/storage";
import {getMaintenanceTypeText} from "@/views/program/data/maintenance";

const KEYS = ["attendance"];

export default {
  name: "Engineering",
  components: { BoxCard, Project },
  props: {
    deptList: { type: Array },
    value: { type: Number },
  },
  data() {
    const options = {};
    KEYS.forEach((key) => (options[`${key}Option`] = {}));
    return {
      deptName: "工程部",
      gutter: [16, 16],
      ...options,
      info: null, // 统计信息
      countInfo: {
        total: 0, // 全寺总人数
        buddhistMonks: 0, // 僧众总人数
        workers: 0, // 职工总人数
      },
      // 今日汇报
      reportInfo: {
        reported: 0, // 已汇报
        unreported: 0, // 未汇报
      },
      // 报修统计
      repairInfo: {
        unfinished: 0, // 未派单
        finished: 0, // 已完成
      },
      repairActive: 2,
      conditionOptions: timeOptions.slice(1),
      maintenanceOptions: [
        { value: 0, label: "未完成" },
        { value: 1, label: "记录表" },
      ],
      attendanceActive: 0,
      maintenanceActive: 0,
      attendanceOptions,
      responseActive: 2,
      responseColumns: [
        {
          title: "排名",
          dataIndex: "rank",
          customRender: (text, record, index) => <div class={`rank ${index < 3 ? "top" : ""}`}>{++index}</div>,
          align: "center",
        },
        { title: "姓名", dataIndex: "name", align: "center" },
        { title: "平均时长(h)", dataIndex: "average_duration", align: "center", width: 120 },
      ],
      responseData: [],
      deviceColumns: [
        { title: "设备名称", dataIndex: "device", align: "center" },
        { title: "保管人", dataIndex: "manager", align: "center" },
      ],
      deviceData: [],
      /* 维保合同 */
      contractColumns: [
        { title: "合同名称", dataIndex: "name", align: "center" },
        { title: "供应商", dataIndex: "supplier", align: "center" },
        {
          title: "期满时间",
          dataIndex: "end_time",
          align: "center",
          customRender: (text) => {
            return `剩余${this.calculateDaysLeftUntilDeadline(text)}天`;
          },
        },
      ],
      contractData: [],
      contractInfo: {
        expire: 0, // 即将到期(30天)
        effective: 0, // 有效
      },
      // 维保单表格表头
      orderColumns: [
        { title: "维保单编号", dataIndex: "order_num", align: "center" },
        { title: "设备名称", align: "center", customRender: (text) => text.keep?.name || "" },
        {
          title: "维保时间",
          align: "center",
          customRender: (text) => {
            const { start_time, end_time } = text;
            return start_time + " 至 " + end_time;
          },
        },
        {
          title: "状态",
          dataIndex: "status",
          align: "center",
          customRender: (text) => {
            const target = statusOptions.find((item) => item.value == text);
            const style = {
              color: target.color,
            };
            return target ? <span style={style}>{target.label}</span> : "";
          },
        },
      ],
      // 维保单记录表头
      recordColumns: [
        { title: "设备/月份", dataIndex: "name", align: "center", fixed: 'left' },
        ...new Array(12)
          .fill(0)
          .reduce((acc, val, index) => {
            const m = (index + 1) + "月";
            acc.push(
              { title: m + "上", dataIndex: `${index}_0`, width: 80, align: "center" },
              { title: m + "下", dataIndex: `${index}_1`, width: 80, align: "center" }
            );
            return acc;
          }, []),
        { title: "维保次数", dataIndex: "keep.type", align: "center", fixed: 'right', customRender: getMaintenanceTypeText },
      ],
      // 维保单表数据
      orderData: [],
      // 维保单记录表数据
      recordData: [],
      orderPagination: {
        total: 0,
        current: 1,
        pageSize: 6,
        showTotal: (total) => `共 ${total} 项数据`,
      },
      recordPagination: {
        total: 0,
        current: 1,
        pageSize: 6,
        showTotal: (total) => `共 ${total} 项数据`,
      },
    };
  },
  computed: {
    dept: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {
    repairActive() {
      this.setRepairInfo();
    },
    attendanceActive() {
      this.getAttendanceChart();
    },
    responseActive() {
      this.setResponseData();
    },
  },
  created() {
    this.getData();
    this.getOrder();
  },
  methods: {
    calculateDaysLeftUntilDeadline(deadline) {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const deadlineDate = getDate(deadline);
      const timeDifference = deadlineDate.getTime() - currentDate.getTime();
      const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
      return daysLeft + 1;
    },
    getData() {
      this.$emit("loading");
      let params = {};
      let temple = getTemple();
      if (temple) {
        params.temple_id = temple;
      }
      this.$axios({
        url: "/admin/dept-datas/get-engineering-data",
        method: "GET",
        params,
        noTempleFilter: true,
      })
        .then((res) => {
          this.info = res;
          this.setCountInfo();
          this.setReportInfo();
          this.setRepairInfo();
          this.setResponseData();
          this.setDeviceData();
          this.setContract();
          this.setOrderRecordData();
          this.init();
        })
        .finally(() => {
          this.$emit("loaded")
        });
    },
    setOrderRecordData() {
      const { keep_order } = this.info;
      if (keep_order) {
        keep_order.forEach((item) => {
          if (item.list) {
            item.list.forEach(d => {
              const date = getDate(d.update_time);
              const key = `${date.getMonth()}_${date.getDate() > 15 ? 1 : 0}`;
              item[key] = date.pattern("MM.dd");
            });
          }
        });
      }
      this.recordData = keep_order || [];
    },
    // 全寺人数
    setCountInfo() {
      const { total_num, sz_num, zg_num } = this.info?.total_num || {};
      this.countInfo.total = total_num || 0;
      this.countInfo.buddhistMonks = sz_num || 0;
      this.countInfo.workers = zg_num || 0;
    },
    // 今日汇报
    setReportInfo() {
      const { reported, unreported } = this.info?.today_report || {};
      this.reportInfo.reported = reported || 0;
      this.reportInfo.unreported = unreported || 0;
    },
    // 报修统计
    setRepairInfo() {
      const { finished_month, unassigned_month, finished_year, unassigned_year } = this.info?.engineer_repair || {};
      switch (this.repairActive) {
        case 2: // 本月
          this.repairInfo.unfinished = unassigned_month || 0;
          this.repairInfo.finished = finished_month || 0;
          break;
        case 3: // 本年
          this.repairInfo.unfinished = unassigned_year || 0;
          this.repairInfo.finished = finished_year || 0;
          break;
        default:
          this.repairInfo.unfinished = 0;
          this.repairInfo.finished = 0;
      }
    },
    // 响应效率
    setResponseData() {
      const { month, year } = this.info?.efficiency || {};
      switch (this.responseActive) {
        case 2: // 本月
          this.responseData = month || [];
          break;
        case 3: // 本年
          this.responseData = year || [];
          break;
        default:
          this.responseData = [];
      }
    },
    // 部门设备
    setDeviceData() {
      this.deviceData = this.info?.dept_device || [];
    },
    // 维保合同
    setContract() {
      const { expire, effective, expire_data } = this.info?.contract || {};
      this.contractInfo.expire = expire || 0;
      this.contractInfo.effective = effective || 0;
      this.contractData = expire_data;
    },
    init() {
      this.getAllChart(KEYS);
    },
    // 获取所有图表
    getAllChart(keys) {
      keys?.forEach((key) => {
        key = capitalizeFirstLetter(key);
        this[`get${key}Chart`]();
      });
    },
    // 考勤
    getAttendanceChart() {
      const kq = this.info?.kq || [];
      const count = kq[this.attendanceActive]?.count || []; // count: ["应到人数"， "在职", "请假", "外派"]
      const data = [
        { name: "在职", value: 0 },
        { name: "请假", value: 0 },
        { name: "外派", value: 0 },
      ];
      const total = count[0] || 0;
      data.forEach((item, index) => {
        item.value = count[index + 1] || 0;
      });
      this.attendanceOption = getAttendancePieChartOption(data, total);
    },
    // 维保单
    getOrder() {
      const { current, pageSize } = this.orderPagination;
      this.$axios({
        url: "/admin/record-keep-order",
        method: "GET",
        params: {
          sort: "-id",
          expand: "keep",
          page: current,
          pageSize,
        },
      }).then((res) => {
        this.orderData = res?.data || [];
        this.orderPagination.total = res?.page?.totalCount || 0;
      });
    },
    tableChange(pagination, type) {
      const key = `${type}Pagination`;
      const pager = { ...this[key] };
      pager.current = pagination.current;
      pager.pageSize = pagination.pageSize;
      this[key] = pager;
      if (type === 'order') {
        this.getOrder();
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import url(../../../common/less/department.less);

.contract-list {
  margin-bottom: 24px;
}
</style>
