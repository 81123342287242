<template>
  <a-row :gutter="gutter" type="flex">
    <a-col :span="6"></a-col>
    <a-col :span="6">
      <BoxCard title="在寺总人数" :subTitle="countInfo.total" class="height mini">
        <div class="department-info-list">
          <div class="department-info-item">
            <div class="info-item-title">僧众总人数</div>
            <div class="info-item-value">{{ countInfo.buddhistMonks }}</div>
          </div>
          <div class="department-info-item">
            <div class="info-item-title">职工总人数</div>
            <div class="info-item-value">{{ countInfo.workers }}</div>
          </div>
        </div>
      </BoxCard>
    </a-col>
    <a-col :span="6">
      <BoxCard title="今日汇报" class="height mini">
        <div class="department-info-list">
          <div class="department-info-item">
            <div class="info-item-title">已汇报</div>
            <div class="info-item-value">{{ reportInfo.reported }}</div>
          </div>
          <div class="department-info-item">
            <div class="info-item-title">未汇报</div>
            <div class="info-item-value">{{ reportInfo.unreported }}</div>
          </div>
        </div>
      </BoxCard>
    </a-col>
    <a-col :span="6">
      <BoxCard title="报修统计" class="height mini">
        <div class="department-info-list">
          <div class="department-info-item">
            <div class="info-item-title">本月</div>
            <div class="info-item-value">{{ repairInfo.month }}</div>
          </div>
          <div class="department-info-item">
            <div class="info-item-title">本年</div>
            <div class="info-item-value">{{ repairInfo.year }}</div>
          </div>
        </div>
      </BoxCard>
    </a-col>
    <a-col :span="6">
      <BoxCard
          title="考勤统计"
          class="height normal"
          show-query
          :options="attendanceOptions"
          :active.sync="attendanceActive"
      >
        <chart :option="attendanceOption" class="chart-box" />
      </BoxCard>
    </a-col>
    <a-col :span="6">
      <BoxCard
          title="接待人数"
          :subTitle="receptionCount"
          show-query
          :options="timeOptions"
          :active.sync="receptionActive"
          class="height normal"
      >
        <chart :option="receptionOption" class="chart-box" />
      </BoxCard>
    </a-col>
    <a-col :span="6">
      <a-row :gutter="gutter" type="flex">
        <a-col :span="24">
          <BoxCard title="投诉统计" class="height small">
            <div class="department-info-list mt-21">
              <div class="department-info-item">
                <div class="info-item-title">本月</div>
                <div class="info-item-value">{{ complainInfo.month }}</div>
              </div>
              <div class="department-info-item">
                <div class="info-item-title">本年</div>
                <div class="info-item-value">{{ complainInfo.year }}</div>
              </div>
            </div>
          </BoxCard>
        </a-col>
        <a-col :span="24">
          <BoxCard title="突发事件处理统计" class="height small">
            <div class="department-info-list mt-21">
              <div class="department-info-item">
                <div class="info-item-title">本月</div>
                <div class="info-item-value">{{ accidentInfo.month }}</div>
              </div>
              <div class="department-info-item">
                <div class="info-item-title">本年</div>
                <div class="info-item-value">{{ accidentInfo.year }}</div>
              </div>
            </div>
          </BoxCard>
        </a-col>
      </a-row>
    </a-col>
    <a-col :span="6">
      <BoxCard title="满意度调查" :title-router="{name: 'Satisfaction'}" class="height normal">
        <template #header>
          <a-popover placement="bottom">
            <template #content>
              <vue-qr
                text="http://ly.zj-qq.cn/survey/satisfaction"
                :margin="8"
                :size="160"/>
            </template>
            <div class="satisfaction-qr">二维码</div>
          </a-popover>
        </template>
        <chart :option="satisfactionOption" class="chart-box" />
      </BoxCard>
    </a-col>
    <a-col :span="12">
      <BoxCard
          title="佛事统计"
          :subTitle="buddhistAffairs"
          show-table
          :columns="buddhistColumns"
          :data="buddhistList"
          show-query
          :options="timeOptions"
          :active.sync="buddhistActive"
          class="height normal"
      >
      </BoxCard>
    </a-col>
    <a-col :span="12">
      <BoxCard
          title="公共区域日常巡逻记录"
          show-table
          :columns="patrolColumns"
          :data="patrolList"
          class="height normal"
      >
        <template #panel>
          <div class="patrol-desc">
            巡查内容：
            1.卫生、公共设施完好情况；2.防火、防盗安全隐患；3.指示标牌完全、清晰、明确；4.弘法宣传栏信息准确，完好；5.大公厕卫生状况；
            6.公共秩序和安全；7.设备设施运行情况
          </div>
        </template>
      </BoxCard>
    </a-col>
  </a-row>
</template>

<script>
import VueQr from "vue-qr";
import BoxCard from "./components/box-card.vue";
import {
  timeOptions,
  attendanceOptions,
  getVerticalBarChartOption,
  getSatisfactionPieChartOption,
  getAttendancePieChartOption,
} from "./constant";
import { capitalizeFirstLetter } from "@/common/js/tool";
import { getTemple } from "@/common/js/storage";

const KEYS = ["attendance", "reception", "satisfaction"];

export default {
  name: "Classroom",
  components: { BoxCard, VueQr },
  props: {
    deptList: { type: Array },
    value: { type: Number },
  },
  data() {
    const options = {};
    KEYS.forEach((key) => (options[`${key}Option`] = {}));
    return {
      deptName: "客堂",
      gutter: [16, 16],
      countInfo: {
        total: 0, // 全寺总人数
        buddhistMonks: 0, // 僧众总人数
        workers: 0, // 职工总人数
      },
      // 今日汇报
      reportInfo: {
        reported: 0, // 已汇报
        unreported: 0, // 未汇报
      },
      // 报修统计
      repairInfo: {
        month: 0, // 本月
        year: 0, // 本年
      },
      // 投诉统计
      complainInfo: {
        month: 0, // 本月
        year: 0, // 本年
      },
      // 突发事件处理统计
      accidentInfo: {
        month: 0, // 本月
        year: 0, // 本年
      },
      ...options,
      receptionCount: 0, // 接待人数
      buddhistAffairs: 0, // 佛事数量
      buddhistColumns: [
        {
          title: "日期",
          align: "center",
          customRender: (text) => {
            const { begin_time } = text;
            return begin_time?.split(" ")[0] || "";
          },
        },
        {
          title: "时间",
          align: "center",
          customRender: (text) => {
            const { begin_time } = text;
            return begin_time?.split(" ")[1].slice(0, 5) || "";
          },
        },
        { title: "佛事名称", dataIndex: "name", align: "center" },
      ],
      buddhistList: [],
      patrolColumns: [
        { title: "巡查时间", dataIndex: "patrol_time", align: "center" },
        {
          title: "巡查内容",
          align: "center",
          customRender: (text) => {
            const { content, status } = text;
            const style = status == 2 ? { color: "#FF4800" } : null; // 1：正常 2：异常
            return <span style={style}>{content}</span>;
          },
        },
        {
          title: "巡查人",
          dataIndex: "wx_user_id",
          align: "center",
          customRender: (text) => <open-data type="userName" openid={text} />,
        },
      ],
      patrolList: [],
      attendanceActive: 0,
      attendanceOptions,
      timeOptions,
      receptionActive: 1,
      buddhistActive: 1,
    };
  },
  computed: {
    dept: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {
    attendanceActive() {
      this.getAttendanceChart();
    },
    receptionActive() {
      this.getReceptionChart();
    },
    buddhistActive() {
      this.setBuddhistList();
    },
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.$emit("loading");
      let params = {};
      let temple = getTemple();
      if (temple) {
        params.temple_id = temple;
      }
      this.$axios({
        url: "/admin/dept-datas/get-ke-tang",
        method: "GET",
        params,
        noTempleFilter: true,
      })
        .then((res) => {
          this.info = res;
          this.setCountInfo();
          this.setReportInfo();
          this.setRepairInfo();
          this.setComplainInfo();
          this.setAccidentInfo();
          this.setBuddhistList();
          this.setPatrolList();
          this.init();
        })
        .finally(() => {
          this.$emit("loaded")
        });
    },
    // 全寺人数
    setCountInfo() {
      const { total_num, sz_num, zg_num } = this.info?.total_num || {};
      this.countInfo.total = total_num || 0;
      this.countInfo.buddhistMonks = sz_num || 0;
      this.countInfo.workers = zg_num || 0;
    },
    // 今日汇报
    setReportInfo() {
      const { reported, unreported } = this.info?.today_report || {};
      this.reportInfo.reported = reported || 0;
      this.reportInfo.unreported = unreported || 0;
    },
    // 报修统计
    setRepairInfo() {
      const { month, year } = this.info?.repair_report || {};
      this.repairInfo.month = month || 0;
      this.repairInfo.year = year || 0;
    },
    // 投诉统计
    setComplainInfo() {
      const { month, year } = this.info?.complaint || {};
      this.complainInfo.month = month || 0;
      this.complainInfo.year = year || 0;
    },
    // 突发事件处理统计
    setAccidentInfo() {
      const { month, year } = this.info?.emergency || {};
      this.accidentInfo.month = month || 0;
      this.accidentInfo.year = year || 0;
    },
    // 佛事统计
    setBuddhistList() {
      const { month, month_num, today, today_num, year, year_num } = this.info?.fs || {};
      switch (this.buddhistActive) {
        case 1: // 今日
          this.buddhistAffairs = today_num || 0;
          this.buddhistList = today || [];
          break;
        case 2: // 本月
          this.buddhistAffairs = month_num || 0;
          this.buddhistList = month || [];
          break;
        case 3: // 本年
          this.buddhistAffairs = year_num || 0;
          this.buddhistList = year || [];
          break;
        default:
          this.buddhistAffairs = 0;
          this.buddhistList = [];
      }
    },
    // 公共区域日常巡逻记录
    setPatrolList() {
      this.patrolList = this.info?.patrol;
    },
    init() {
      this.getAllChart(KEYS);
    },
    // 获取所有图表
    getAllChart(keys) {
      keys?.forEach((key) => {
        key = capitalizeFirstLetter(key);
        this[`get${key}Chart`]();
      });
    },
    // 考勤
    getAttendanceChart() {
      const kq = this.info?.kq || [];
      const count = kq[this.attendanceActive]?.count || []; // count: ["应到人数"， "在职", "请假", "外派"]
      const data = [
        { name: "在职", value: 0 },
        { name: "请假", value: 0 },
        { name: "外派", value: 0 },
      ];
      const total = count[0] || 0;
      data.forEach((item, index) => {
        item.value = count[index + 1] || 0;
      });
      this.attendanceOption = getAttendancePieChartOption(data, total);
    },
    // 接待
    getReceptionChart() {
      const {
        dinner_num,
        dinner_num_month,
        dinner_num_year,
        month,
        shangxiang_num_month,
        shangxiang_num_today,
        shangxiang_num_year,
        today,
        year,
      } = this.info?.reception || {};
      const data = [
        { name: "用餐", value: 0 },
        { name: "上香", value: 0 },
      ];
      switch (this.receptionActive) {
        case 1: // 今日
          data[0].value = dinner_num || 0;
          data[1].value = shangxiang_num_today || 0;
          this.receptionCount = today || 0;
          break;
        case 2: // 本月
          data[0].value = dinner_num_month || 0;
          data[1].value = shangxiang_num_month || 0;
          this.receptionCount = month || 0;
          break;
        case 3: // 本年
          data[0].value = dinner_num_year || 0;
          data[1].value = shangxiang_num_year || 0;
          this.receptionCount = year || 0;
          break;
        default:
          data[0].value = 0;
          data[1].value = 0;
          this.receptionCount = 0;
      }
      this.receptionOption = getVerticalBarChartOption(data, "接待");
    },
    // 满意度(先写死) TODO
    getSatisfactionChart() {
      const data = [
        { name: "满意", value: 50 },
        { name: "较满意", value: 60 },
        { name: "一般", value: 3 },
        { name: "不满意", value: 0 },
      ];
      this.satisfactionOption = getSatisfactionPieChartOption(data);
    },
  },
};
</script>

<style lang="less" scoped>
@import url(../../../common/less/department.less);

.mt-21 {
  margin-top: 21px;
}

.patrol-desc {
  color: var(--text_color);
  font-size: 12px;
  line-height: 20px;
  margin: -12px 0 10px 0;
}

.satisfaction-qr {
  color: #FF8C00;
  font-size: 16px;
  line-height: 28px;
  cursor: pointer;
}
</style>
