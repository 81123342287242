<template>
  <div class="box-card">
    <a-tabs default-active-key="1" style="height: 100%;">
      <a-radio-group slot="tabBarExtraContent" v-model="status" size="small" class="query-radio-group">
        <a-radio-button :value="option.key" v-for="option in statusOptions" :key="option.key">
          {{ option.title }}
        </a-radio-button>
      </a-radio-group>
      <a-tab-pane key="1" :tab="getTabTitle('部门项目', programTotal)">
        <DepartmentProgram :dept="dept" :type="status" hide-title style="height: 100%;" @total="getProgramTotal" />
      </a-tab-pane>
      <a-tab-pane key="2" :tab="getTabTitle('个人任务', taskTotal)">
        <PersonnelTask :dept="dept" :type="status" hide-title style="height: 100%;" @total="getTaskTotal" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import DepartmentProgram from "../../layouts/DepartmentProgram.vue";
import PersonnelTask from "../../layouts/PersonnelTask.vue";

export default {
  name: "Project",
  components: { DepartmentProgram, PersonnelTask },
  props: {
    deptList: { type: Array },
    value: { type: Number },
  },
  data() {
    return {
      // dept: 19, // 工程部
      programTotal: null,
      taskTotal: null,
      statusOptions: [
        { key: 0, title: "进行中" },
        { key: 1, title: "已完成" },
        { key: 2, title: "已超时" },
      ],
      status: 0,
    };
  },
  computed: {
    dept() {
      const target = this.deptList?.find((item) => item.value == this.value);
      return target?.dept_id;
    },
  },
  methods: {
    getTabTitle(text, total) {
      return total === null ? text : `${text}(${total})`;
    },
    getProgramTotal(total) {
      this.programTotal = total || 0;
    },
    getTaskTotal(total) {
      this.taskTotal = total || 0;
    },
  },
};
</script>

<style lang="less" scoped>
@import url(../../../../common/less/department.less);
@import url(../../../../common/less/radio.less);

::v-deep .ant-tabs {
  .ant-tabs-tab {
    color: #ff8c00;
  }
  .ant-tabs-ink-bar {
    background-color: #ff8c00;
  }
  .ant-tabs-content {
    height: calc(100% - 60px);
  }
}
::v-deep .data-area {
  margin: 0;
}

</style>
