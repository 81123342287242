<template>
  <div class="piece-box inner-content member-portrait">
    <div class="piece-box-title flex-box align-center">
      <div class="flex-grow">成员画像</div>
      <piece-tab v-model="type" :tabs="personTypes"></piece-tab>
    </div>
    <div class="member-portrait-chart"></div>
    <div class="member-portrait-score">平均得分：{{average}}分</div>
  </div>
</template>

<script>
    import * as echarts from "echarts";
    import pieceTab from "../../../components/piece-tab";
    import {getStaffRadar} from "../../../common/hr/book";
    const options  = {
        radar: {
            radius: '50%',
            indicator: []
        },
        series: [
            {
                name: '僧众',
                type: 'radar',
                data: [
                    {
                        value: [80, 80, 80, 80, 80, 80],
                        name: '能力值'
                    }
                ]
            }
        ]
    };
    export default{
        name: "MemberPortrait",
        components: {
            pieceTab
        },
        props: {
            number: Array
        },
        data() {
            return {
                type: 1,
                average: 80
            }
        },
        computed: {
            personTypes() {
                const res = [
                    {
                        key: 1,
                        title: "僧众",
                        total: 0,
                        totalTitle: "应到人数",
                        count: {}
                    },
                    {
                        key: 2,
                        title: "职工",
                        total: 0,
                        totalTitle: "应到人数",
                        count: {}
                    },
                ];
                const number = this.number;
                if(number) {
                    res.forEach((item, i) => {
                        res.count = number[i] || {};
                    });
                }
                return res;
            }
        },
        watch: {
            type() {
                this.initChart();
            },
            personTypes() {
                this.initChart();
            }
        },
        mounted() {
            this.initChart();
        },
        methods: {
            initChart() {
                if(!this.chart) {
                    this.chart = echarts.init(this.$el.querySelector(".member-portrait-chart"));
                }
                const type = this.personTypes.find(t => t.key == this.type);
                if(type) {
                    const indicator = getStaffRadar(this.type);
                    const map = type.count;
                    const value = indicator.map(item => map[item.cat] || 80);
                    this.average = Math.round(value.reduce((acc, val) => acc + val, 0) / value.length);
                    options.radar.indicator = indicator;
                    options.series[0].data[0].value = value;
                }
                this.chart.setOption(options);
            }
        }
    }
</script>

<style lang="less">
  .member-portrait {
    display: flex;
    flex-direction: column;
  }
  .member-portrait-chart {
    flex: 1;
    margin-top: 10px;
  }
  .member-portrait-score {
    text-align: right;
  }
</style>
