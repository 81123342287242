<template>
  <div class="notice-list" v-if="arr.length">
    <div class="notice-item" v-for="(item, index) in arr" :key="index" @click="showDetail(item)">
      <!-- <div class="notice-tag" :class="item.type ? 'tag-' + item.type : ''">{{ tagName(item.type) }}</div> -->
      <a-icon type="file-text" class="notice-icon" />
      <div class="notice-content">
        <div class="notice-title ellipsis">{{ item.title }}</div>
        <div class="notice-date">{{ item.date }}</div>
      </div>
      <a-icon type="right" class="arrow-icon" />
    </div>
    <a-modal :title="apply ? apply.title : ''" centered :width="600" :footer="null" v-model="ds">
      <detail-view :list="apply && apply.showList ? apply.showList : []"></detail-view>
    </a-modal>
  </div>
  <a-empty :image="emptyImage" v-else class="notice-empty" />
</template>

<script>
import { Empty } from "ant-design-vue";
import { dealHRApply, getApplyMsgTitle } from "../../../../common/hr/apply";
import detailView from "../../../../components/detail-view";
import { getDate } from "../../../../common/js/tool";

export default {
  name: "NoticeList",
  components: { detailView },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      apply: null,
      ds: false,
      emptyImage: Empty.PRESENTED_IMAGE_SIMPLE,
    };
  },
  computed: {
    arr() {
      let list = this.list;
      if (list) {
        return list.map((item) => {
          return {
            ...item,
            title: getApplyMsgTitle(item.applyTemp.type),
            date: getDate(item.update_time).pattern("yyyy-MM-dd"),
          };
        });
      } else {
        return null;
      }
    },
  },
  methods: {
    getShowList(item) {
      let keys = [];
      if (item.applyTemp) {
        let type = item.applyTemp.type;
        switch (type * 1) {
          case 1:
            keys = ["fm"];
            break;
          case 3:
            keys = ["name"];
            break;
          case 5:
            keys = ["staff", "old_dept", "old_post", "new_dept", "new_post"];
            break;
        }
      }
      return [{ label: "公示日期", value: item.date }].concat(keys.map((key) => item.form.find((f) => f.key == key)));
    },
    hideApply() {
      this.apply = null;
      this.ds = false;
    },
    showDetail(record) {
      dealHRApply(record);
      if (!record.showList) {
        record.showList = this.getShowList(record);
      }
      this.apply = record;
      this.ds = true;
    },
  },
};
</script>

<style lang="less" scoped>
.notice-list {
  overflow-y: auto;
}
.notice-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #f8f7f4;
  }
  &:not(:last-of-type) {
    margin-bottom: 14px;
  }
}
.notice-icon {
  font-size: 18px;
}
.notice-content {
  flex: 1;
  min-width: 0;
  margin: 0 12px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.notice-title {
  color: #333;
  font-size: 16px;
  font-weight: 400;
}
.notice-date {
  color: #999;
  font-size: 12px;
  font-weight: 400;
}
.arrow-icon {
  flex-shrink: 0;
  font-size: 12px;
}

.notice-empty {
  margin: 24px 0;
}
</style>
