<template>
  <a-row :gutter="gutter" type="flex">
    <a-col :span="12">
      <a-row :gutter="gutter" type="flex">
        <a-col :span="12">
          <div class="height mini department-box">
            <div class="nav-list">
              <div
                  class="nav-item"
                  :class="{ active: nav.value === active }"
                  v-for="nav in navList"
                  :key="nav.value"
                  @click="selectNav(nav.value)"
              >
                {{ nav.label }}
              </div>
            </div>
          </div>
        </a-col>
        <a-col :span="12">
          <BoxCard title="志工状态分布" class="status-box height mini">
            <chart :option="statusOption" class="chart-box" />
          </BoxCard>
        </a-col>
        <a-col :span="12">
          <BoxCard title="各组正式+实习志工人数" class="height normal">
            <chart :option="fiOption" class="chart-box" />
          </BoxCard>
        </a-col>
        <a-col :span="12">
          <BoxCard title="各组新增注册" class="height normal">
            <chart :option="registerOption" class="chart-box" />
          </BoxCard>
        </a-col>
        <a-col :span="12">
          <BoxCard title="各组志工服务时长" class="height normal">
            <chart :option="serveOption" class="chart-box" />
          </BoxCard>
        </a-col>
        <a-col :span="12">
          <BoxCard title="参与培训人次" class="height normal">
            <chart :option="trainOption" class="chart-box" />
          </BoxCard>
        </a-col>
      </a-row>
    </a-col>
    <a-col :span="12">
      <a-row :gutter="gutter" type="flex">
        <a-col :span="24">
          <div class="box-card flex-box justify-between height mini">
            <div class="flex-grow flex-box vertical">
              <div class="card-title">性别比例</div>
              <chart :option="sexOption" class="chart-box flex-grow" />
            </div>
            <div class="flex-grow flex-box vertical ml-20">
              <div class="card-title">学历分布</div>
              <chart :option="qualificationOption" class="chart-box flex-grow" />
            </div>
            <div class="flex-grow flex-box vertical ml-20">
              <div class="card-title">年龄分布</div>
              <chart :option="ageOption" class="chart-box flex-grow" />
            </div>
          </div>
        </a-col>
        <template v-if="isDay">
          <a-col :span="24">
            <BoxCard title="今日志工报名/到岗人数" class="height normal">
              <chart :option="enrollOption" class="chart-box" />
            </BoxCard>
          </a-col>
          <a-col :span="24">
            <BoxCard title="今日报备" class="height normal">
              <chart :option="reportOption" class="chart-box" />
            </BoxCard>
          </a-col>
        </template>
        <template v-else>
          <a-col :span="24">
            <div class="info-box box-card">
              <div class="volunteer-info-item-box">
                <div class="volunteer-info-item flex-box" v-for="(info, index) in infoList.slice(0, 2)" :key="index">
                  <div class="volunteer-info-icon-box flex-box align-center justify-center">
                    <i class="volunteer-info-icon" :class="`icon-${info.icon}`"></i>
                  </div>
                  <div>
                    <div class="volunteer-info-title">{{ info.title }}</div>
                    <div>
                      <span class="volunteer-info-value">{{ info.value }}</span>
                      <span class="volunteer-info-unit">{{ info.unit }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="volunteer-info-item-box">
                <div class="volunteer-info-item flex-box" v-for="(info, index) in infoList.slice(2, 4)" :key="index">
                  <div class="volunteer-info-icon-box flex-box align-center justify-center">
                    <i class="volunteer-info-icon" :class="`icon-${info.icon}`"></i>
                  </div>
                  <div>
                    <div class="volunteer-info-title">{{ info.title }}</div>
                    <div>
                      <span class="volunteer-info-value">{{ info.value }}</span>
                      <span class="volunteer-info-unit">{{ info.unit }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="volunteer-info-item-box">
                <div class="volunteer-info-item flex-box" v-for="(info, index) in infoList.slice(-2)" :key="index">
                  <div class="volunteer-info-icon-box flex-box align-center justify-center">
                    <i class="volunteer-info-icon" :class="`icon-${info.icon}`"></i>
                  </div>
                  <div>
                    <div class="volunteer-info-title">{{ info.title }}</div>
                    <div>
                      <span class="volunteer-info-value">{{ info.value }}</span>
                      <span class="volunteer-info-unit">{{ info.unit }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a-col>
          <a-col :span="24">
            <BoxCard title="工作计划" show-table :columns="columns" :data="list" class="height medium">
              <template #query>
                <a-icon type="right" @click="goPlan" />
              </template>
            </BoxCard>
          </a-col>
        </template>
      </a-row>
    </a-col>
  </a-row>
</template>

<script>
import { scheduleOptions } from "@/common/constant/program/type";
import { getTemple } from "@/common/js/storage";
import { getKeyTitle, timestampConvertString, getCurrentDateTimestamp } from "@/common/js/tool";
import BoxCard from "./components/box-card.vue";
import {
  getSexPieChartOption,
  getQualificationPieChartOption,
  getAgePieChartOption,
  getStatusPieChartOption,
  getHorizontalBarChartOption,
  getEnrollBarChartOption,
  getVerticalBarChartOption,
} from "./constant";

const KEYS = ["sex", "qualification", "age", "status", "fi", "register", "serve", "train", "enroll", "report"];
const colorMap = {
  fi: ["#FF8C00", "#FFCE7E"],
  register: ["#0AB444", "#5AE388"],
  serve: ["#00A3FF", "#65C8FF"],
  train: ["#8D43EB", "#B082EA"],
};

export default {
  name: "Volunteer",
  components: { BoxCard },
  props: {
    deptList: { type: Array },
    value: { type: Number },
  },
  data() {
    const options = {};
    KEYS.forEach((key) => (options[`${key}Option`] = {}));
    return {
      deptName: "志工团",
      gutter: [16, 16],
      active: "month",
      navList: [
        { label: "日报", value: "day" },
        { label: "月报", value: "month" },
        { label: "年报", value: "year" },
      ],
      ...options,
      infoList: [
        {
          title: "出勤总人次",
          value: 0,
          unit: "人",
          icon: "attendance-count",
        },
        {
          title: "新增注册",
          value: 0,
          unit: "人",
          icon: "new-register",
        },
        {
          title: "出勤总时长",
          value: 0,
          unit: "小时",
          icon: "attendance-hours",
        },
        {
          title: "面试通过",
          value: 0,
          unit: "人",
          icon: "interview-passed",
        },
        {
          title: "注册总人数",
          value: 0,
          unit: "人",
          icon: "register-count",
        },
        {
          title: "新增正式",
          value: 0,
          unit: "人",
          icon: "new-formal",
        },
      ],
      columns: [
        {
          title: "日期",
          customRender: (text) => {
            const { start_time, end_time } = text;
            return `${timestampConvertString(start_time, "yyyy-MM-dd")} 至 ${timestampConvertString(
              end_time,
              "yyyy-MM-dd"
            )}`;
          },
        },
        { title: "工作内容", dataIndex: "content" },
        {
          title: "项目进度",
          customRender: (text) => {
            let { schedule, start_time, end_time } = text;
            if (!schedule) {
              let now = getCurrentDateTimestamp();
              if (now < start_time) {
                schedule = 2;
              } else if (now > end_time) {
                schedule = 3;
              } else {
                schedule = 1;
              }
            }
            return getKeyTitle(scheduleOptions, schedule, "value", "label") || "-";
          },
        },
        { title: "志工报名人数", dataIndex: "enroll_num" },
      ],
      list: [],
      detail: {},
    };
  },
  computed: {
    isDay() {
      return this.active == "day";
    },
    dept: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {
    active() {
      this.init();
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.loading = true;
      this.getData();
      this.getPlan();
    },
    getData() {
      this.$emit("loading");
      let params = {
        date: this.active,
      };
      let temple = getTemple();
      if (temple) {
        params.temple_id = temple;
      }
      this.$axios({
        url: "/admin/dept-datas/get-volunteer-data",
        method: "GET",
        params,
        noTempleFilter: true,
      })
        .then((res) => {
          this.detail = res;
          let keys = [...KEYS];
          if (!this.isDay) {
            keys.splice(-2);
            this.getStatistics();
          }
          this.getAllChart(keys);
        })
        .finally(() => {
          this.$emit("loaded")
        });
    },
    getPlan() {
      if (this.isDay) {
        return;
      }
      this.$axios({
        url: "/admin/volunteer-work-plan",
        method: "GET",
        params: {
          sort: "-id",
        },
      }).then((res) => {
        this.list = res.data;
      });
    },
    roundNum(num) {
      if (num) {
        return Number(num.split(".")[0]);
      } else {
        return 0;
      }
    },
    // 首字母大写
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    dealData(detailKey, key = "value", title = "name") {
      return this.detail?.[detailKey]?.map((item) => ({ value: item[key], name: item[title] })) || [];
    },
    // 统计信息
    getStatistics() {
      const {
        attendance_num,
        attendance_duration,
        enrollment,
        add_register,
        interview,
        formal,
      } = this.detail.people_num;
      let infoList = this.infoList;
      infoList[0].value = attendance_num || 0;
      infoList[1].value = add_register || 0;
      infoList[2].value = attendance_duration || 0;
      infoList[3].value = interview || 0;
      infoList[4].value = enrollment || 0;
      infoList[5].value = formal || 0;
    },
    // 获取所有图表
    getAllChart(keys) {
      keys?.forEach((key) => {
        key = this.capitalizeFirstLetter(key);
        this[`get${key}Chart`]();
      });
    },
    // 性别比例
    getSexChart() {
      const data = this.dealData("sex");
      this.sexOption = getSexPieChartOption(data);
    },
    // 学历分布
    getQualificationChart() {
      const data = this.dealData("education");
      this.qualificationOption = getQualificationPieChartOption(data);
    },
    // 年龄分布
    getAgeChart() {
      const data = this.dealData("age");
      this.ageOption = getAgePieChartOption(data);
    },
    // 志工状态分布
    getStatusChart() {
      const data = this.dealData("status");
      this.statusOption = getStatusPieChartOption(data);
    },
    // 各组正式+实习志工人数
    getFiChart() {
      const data = this.dealData("formal_practice", "num");
      this.setColor(data, "fi");
      this.fiOption = getHorizontalBarChartOption(data);
    },
    // 各组新增注册
    getRegisterChart() {
      const data = this.dealData("group_register", "add_register");
      this.setColor(data, "register");
      this.registerOption = getHorizontalBarChartOption(data);
    },
    // 各组志工服务时长
    getServeChart() {
      const data = this.dealData("group_serve", "duration");
      this.setColor(data, "serve");
      this.serveOption = getHorizontalBarChartOption(data);
    },
    // 参与培训人次
    getTrainChart() {
      const data = this.dealData("train", "num");
      this.setColor(data, "train");
      this.trainOption = getHorizontalBarChartOption(data);
    },
    // 今日志工报名/到岗人数
    getEnrollChart() {
      const {
        morning_enroll_num,
        morning_arrive_num,
        afternoon_enroll_num,
        afternoon_arrive_num,
        today_enroll_num,
        today_arrive_num,
      } = this.detail.classes;
      const data = [
        { product: "上午班", 报名: morning_enroll_num || 0, 到岗: morning_arrive_num || 0 },
        { product: "下午班", 报名: afternoon_enroll_num || 0, 到岗: afternoon_arrive_num || 0 },
        { product: "全班", 报名: today_enroll_num || 0, 到岗: today_arrive_num || 0 },
      ];
      this.enrollOption = getEnrollBarChartOption(data);
    },
    // 今日报备
    getReportChart() {
      const data = this.dealData("today_report");
      this.reportOption = getVerticalBarChartOption(data, "今日报备");
    },
    setColor(data, key) {
      data?.forEach((item, index) => {
        if (index < 3) {
          item.itemStyle = {
            color: colorMap[key][0],
          };
        } else {
          item.itemStyle = {
            color: colorMap[key][1],
          };
        }
      });
    },
    selectNav(value) {
      this.active = value;
    },
    goPlan() {
      this.$router.push({ name: "ProgramPlan" });
    },
  },
};
</script>
<style lang="less" scoped>
@import url(../../../common/less/department.less);

.department-box {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-left: 126px;
  padding: 34px 28px 0 0;
  z-index: 2;
}
.nav-list {
  flex: 1;
  height: 32px;
  display: flex;
  background-color: #f3f3f3;
  padding: 2px;
  border-radius: 3px;
  margin-left: 12px;
  .nav-item {
    flex: 0 0 60px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    color: var(--title_text);
    cursor: pointer;
    &.active {
      color: #fff;
      background-color: #ff8c00;
      border-radius: 2px;
    }
  }
}
.ml-20 {
  margin-left: 20px;
}
/deep/ .status-box {
  .card-header {
    padding-bottom: 12px !important;
  }
}
.info-box {
  display: flex;
  justify-content: space-between;
  padding: 30px 34px;
  height: 216px;
  box-sizing: border-box;
}
.volunteer-info-item-box {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.volunteer-info-item {
  white-space: nowrap;
  .volunteer-info-title {
    color: var(--text_color);
    line-height: 22px;
  }
  .volunteer-info-value {
    color: var(--title_color);
    font-size: 28px;
    font-weight: 600;
    line-height: 36px;
  }
  .volunteer-info-unit {
    color: var(--title_color);
    line-height: 22px;
    margin-left: 4px;
  }
}
.volunteer-info-icon-box {
  width: 56px;
  height: 56px;
  border-radius: 6px;
  background-color: #f8f3e7;
  margin-right: 16px;
  .volunteer-info-icon {
    font-size: 32px;
    color: #ff8c00;
  }
}
.program-dept-select {
  width: 90px;
}
</style>
